import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSchedule } from 'store/Schedule/actions';
import { selectRowsSelected } from 'store/Schedule/selectors';
import IconButton from 'Common/Components/IconButton';
import useModal from 'Hooks/useModal';
import Modal from 'Common/Components/Modal';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as WarningIcon } from 'Images/warning-delete.svg';
import { Wrapper, Trash } from './styled';

const Delete = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [ModalWarning, showModalWarning, toggleModalWarning] = useModal(Modal);
  const rowsSelected = useSelector(selectRowsSelected);

  const closeModal = () => {
    toggleModalWarning(false);
  };

  const acceptModal = () => {
    dispatch(removeSchedule());
    closeModal();
  };

  if (isEmpty(rowsSelected)) return null;
  return (
    <Wrapper>
      <IconButton title="Delete" onClick={ () => toggleModalWarning(true) } icon={ <Trash /> } />
      {showModalWarning && <ModalWarning icon={ <WarningIcon /> } title={ intl.formatMessage({ id: 'schedule.delete.title' }) } onAccept={ acceptModal } onClose={ closeModal }>{ intl.formatMessage({ id: 'schedule.delete.message' }) }</ModalWarning>}
    </Wrapper>
  );
};
export default Delete;
