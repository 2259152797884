import React from 'react';
import { PropTypes } from 'prop-types';
import ActionButton from './ActionButton';
import {
  Wrapper, EditIcon, CopyIcon, DeleteIcon
} from './styled';

const ActionBar = ({
  showActionBar, handleClick, handleDeletePipeline, handleCopy
}) => (
  <>
    { showActionBar
      ? (
      <Wrapper>
        <ActionButton onClick={ handleClick } title="Edit" icon={ <EditIcon /> } />
        <ActionButton onClick={ handleCopy } title="Duplicate" icon={ <CopyIcon /> } />
        <ActionButton onClick={ handleDeletePipeline } title="Delete" icon={ <DeleteIcon /> } />
      </Wrapper>
        )
      : null}
  </>
);

ActionBar.propTypes = {
  showActionBar: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDeletePipeline: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired
};

export default ActionBar;
