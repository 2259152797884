import fetchApi from 'services/api/connector';

export const fetchJobs = () => fetchApi('jobs', { method: 'GET' });
export const createJob = (data) => fetchApi('jobs', { method: 'POST', data });
export const updateJob = (id, data) => fetchApi(`jobs/${id}`, { method: 'PATCH', data });
export const deleteJob = (id) => fetchApi(`jobs/${id}`, { method: 'DELETE' });
export const fetchTasks = () => fetchApi('tasks', { method: 'GET' });
export const createTask = (data) => fetchApi('tasks', { method: 'POST', data });
export const editTask = (id, data) => fetchApi(`tasks/${id}`, { method: 'PATCH', data });
export const deleteTask = (id) => fetchApi(`tasks/${id}`, { method: 'DELETE' });
export const fetchFields = () => fetchApi('tasks/fields', { method: 'GET' });
export const copyJob = (id) => fetchApi(`jobs/${id}/clone`, { method: 'POST' });
