import React from 'react';
import { DashboardTour } from 'Common/Components/Contextualization/Tour';
import DashboardLayout from '../DashboardLayout';

const DashboardContext = () => {
  return (
    <>
      <DashboardLayout />
      <DashboardTour />
    </>
  );
};
export default DashboardContext;
