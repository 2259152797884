import styled from 'styled-components';

export { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';

export const Button = styled.div`
    user-select: none;
    height: 24px;
    width: 118px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    cursor: pointer;
    display: flex;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    font-size: 11.5px;
    font-weight: 500;
    line-height: 34px;
    color: #fff;
    border-radius: 2px;
    background-color: #0082ED;

    & > svg {
        height: 11px;
        margin-right: 10px;
        & > path {
        fill: #fff;
        }
    }

    &:hover {
        background-color: #2996f0;
    }

    &:active {
        background-color: #53a9ff;
    }
`;
Button.displayName = 'AddButton';
