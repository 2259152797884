import colors from 'Styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 39px 34px;
  flex-direction: column;
  width: 100%;
  max-width: 515px;
  border-top-style: solid;
  border-top-color: ${colors.softBlue};
  border-top-width: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  & > button {
    flex: 1 1 0px;
  }
`;
WrapperButtons.displayName = 'WrapperButtons';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  margin-bottom: 17px;
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 12.5px;
  line-height: 1.44;
  color: ${colors.grey19};
  margin-bottom: 17px;
`;
Description.displayName = 'Description';

export const CancelButton = styled.button`
  border: 1px solid #d4d7e5;
  color: ${colors.darkBlueGrey};
  background-color: white;
  margin-right: 13px;
  font-size: 14.5px;
  padding: 7px 21px;
  border-radius: 3px;
  font-weight: 400;
`;
CancelButton.displayName = 'CancelButton';

export const BlueText = styled(Description)`
  padding: 0 0 0 5px;
  color: ${colors.blueCerulean};
  display: inline;
`;
BlueText.displayName = 'BlueText';
