import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectServicesSelected, selectServicesCount,
  selectIsFetching, selectShowDeleteConfirmation,
  selectShowDetail,
  selectSelectAllServices
} from 'store/ServicesGeneral/selectors';
import {
  hideServiceModalDelete,
  setPageNumberServices
} from 'store/ServicesGeneral/actions';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/servicesGeneral/actions';
import { selectServicesFields } from 'store/Preferences/servicesGeneral/selectors';
import CustomActionBar from 'Screens/Contextualization/Services/components/CustomActionBar';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const servicesSelected = useSelector(selectServicesSelected);
  const servicesCount = useSelector(selectServicesCount);
  const page = useSelector((state) => selectPage('servicesGeneral', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('servicesGeneral', state));
  const isFetching = useSelector(selectIsFetching);
  const fields = useSelector(selectServicesFields);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const showServiceDetail = useSelector(selectShowDetail);
  const selectAll = useSelector(selectSelectAllServices);

  return (
    <CustomActionBar
      setFF={ setFF }
      servicesSelected={ servicesSelected }
      servicesCount={ servicesCount }
      page={ page }
      rowsPerPage={ rowsPerPage }
      setPage={ (page) => dispatch(setPageNumberServices(page)) }
      entity="servicesGeneral"
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
      isFetching={ isFetching }
      showDeleteConfirmation={ showDeleteConfirmation }
      fields={ fields }
      hideServiceModalDelete={ () => dispatch(hideServiceModalDelete()) }
      showServiceDetail={ showServiceDetail }
      selectAll={ selectAll }
    />
  );
};

export default ActionBar;
