import React from 'react';
import { useDispatch } from 'react-redux';
import { IpWrapper, Ip, GoToButton, GoToIcon } from 'Screens/Contextualization/Host/components/Table/styled';
import { redirect } from 'store/Router/actions';
import { redirectToHostDetailGeneral, selectHostRow, unSelectAllHosts } from 'store/HostsGeneral/actions';
import { selectAssetRow } from 'store/Host/actions';

export const IpColumn = ({ host, children, showIcon }) => { // eslint-disable-line import/prefer-default-export
  const dispatch = useDispatch();
  const accessHost = (e) => {
    dispatch(redirect(`/host/${host.workspace_name}/${host.id}/services`));
    dispatch(selectAssetRow(e, host));
  };

  const handleClick = (e) => {
    dispatch(unSelectAllHosts());
    dispatch(redirectToHostDetailGeneral(host.id));
    dispatch(selectHostRow(e, host));
  };
  return (
    <IpWrapper>
      <Ip onClick={ handleClick }>{children}</Ip>
     { showIcon &&
      <GoToButton onClick={ accessHost } title="Explore Asset">
        <GoToIcon />
      </GoToButton>
      }
    </IpWrapper>
  );
};
