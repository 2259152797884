import React from 'react';
import { Wrapper, Title, Description } from './styled';

const Warning = () => (
  <Wrapper>
    <Title>Warning:</Title>
    <Description>This action will delete matching objects and cant be undone.</Description>
  </Wrapper>
);

export default Warning;
