import get from 'lodash/get';
import filter from 'lodash/filter';
import { selectWorkspaces } from 'store/Workspace/selectors';
import sortArrayByName from 'Common/Functions/sortArrayByName';

export const selectPipelines = (state) => get(state, 'pipelines.pipelinesList', []);
export const selectSearchQuery = (state) => get(state, 'pipelines.searchQuery', '');
export const selectFilteredPipelines = (state) => {
  const pipelines = selectPipelines(state);
  const searchQuery = selectSearchQuery(state);
  return filter(pipelines, (pipeline) => (pipeline.name.indexOf(searchQuery) > -1));
};
export const selectPipelineSelected = (state) => get(state, 'pipelines.pipelineSelected', {});
export const selectIsRunning = (state) => get(state, 'pipelines.isRunning', false);
export const selectIsCreating = (state) => get(state, 'pipelines.isCreating', false);
export const selectIsEditing = (state) => get(state, 'pipelines.isEditing', false);

export const selectInputField = (state, inputType) => get(state, `pipelines.pipelineSelected.${inputType}`, '');
export const selectId = (state) => get(state, 'pipelines.pipelineSelected.id', '');
export const selectWorkspace = (state) => {
  const workspaceList = selectWorkspaces(state);
  const workspaceId = get(state, 'pipelines.pipelineSelected.workspace_id', '');
  const isCreating = selectIsCreating(state);
  let workspaceName = '';
  if (isCreating) {
    const workspace = workspaceId && workspaceList && workspaceList.filter((ws) => ws._id === workspaceId);
    workspaceName = workspace && workspace.length ? workspace[0].name : '';
  } else {
    const ws = workspaceId && workspaceList && workspaceList.filter((ws) => ws.name === workspaceId);
    workspaceName = ws.length ? ws[0].name : '';
  }
  return workspaceName;
};

export const selectJobsSelected = (state) => get(state, 'pipelines.pipelineSelected.jobs', []);
export const selectRules = (state) => get(state, 'pipelines.pipelineSelected.rules', []);
export const selectRedirectTo = (state) => get(state, 'pipelines.redirectTo', '');
export const selectJobsList = (state) => {
  const jobs = get(state, 'pipelines.jobsList', []);
  const sortedJobs = sortArrayByName(jobs);
  return sortedJobs;
};
export const SelectWs = (state) => get(state, 'pipelines.pipelineSelected.workspace_id', '');
export const selectError = (state) => get(state, 'pipelines.error', false);
export const selectErrorMessage = (state) => get(state, 'pipelines.errorMessage', '');
