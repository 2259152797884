import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  display: flex;
`;
Header.displayName = 'Header';

export const StepContainer = styled.div`
  margin-right: 25px;
`;
StepContainer.displayName = 'StepContainer';

export const HeaderContainer = styled.div`
  padding-top: 60px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const Title = styled.span`
  font-size: 14.5px;
  font-weight: 500;
  color: #000;
  margin-bottom: 9px;
`;
Title.displayName = 'Title';

export const Description = styled.span`
  font-size: 13.5px;
  color: ${colors.greyBlue};
`;
Description.displayName = 'Description';

export const SelectTarget = styled.div`
  display: flex;
  margin-bottom: 90px;
`;
SelectTarget.displayName = 'SelectTarget';

export const DropdownWrapper = styled.div`
  width: 100%;
`;
DropdownWrapper.displayName = 'DropdownWrapper';
