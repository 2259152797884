import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CopyLink from './components/CopyLink';
import CopyApiLink from './components/CopyApiLink';
import { Wrapper, InputTitle, IconVulnerability } from './styled';
import { selectReadOnly } from 'store/Faraday/selectors';
import { selectVulnDetail } from 'store/ManageGeneral/selectors';
import { showBulkUpdateModal } from 'store/ManageGeneral/actions';
import { useIntl } from 'react-intl';

const Title = () => {
  const dispatch = useDispatch();
  const vulnDetail = useSelector(selectVulnDetail);
  const [vulnName, setVulnName] = useState(vulnDetail.name);
  const readOnly = useSelector(selectReadOnly);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.cm.edit.name' });

  useEffect(() => {
    setVulnName(vulnDetail.name);
  }, [vulnDetail]);

  const handleChange = (e) => {
    const name = e.target.value;
    dispatch(showBulkUpdateModal('name', name));
  };

  return (
    <Wrapper>
      <IconVulnerability />
      <InputTitle title={ title } value={ vulnName } onClick={ handleChange } disabled={ readOnly } />
      <CopyLink />
      <CopyApiLink />
    </Wrapper>
  );
};

export default Title;
