import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button, PlusIcon
} from './styled';

const AddButton = ({ onAdd, show }) => {
  const intl = useIntl();
  if (!show) return null;
  return (
    <Button title={ intl.formatMessage({ id: 'jobs.attributes.add' }) } onClick={ onAdd }>
      <PlusIcon />
      <FormattedMessage id="jobs.attributes.add" />
    </Button>
  );
};

AddButton.propTypes = {
  show: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired
};

export default AddButton;
