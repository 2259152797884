/* eslint-disable no-negated-condition */
import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as _ArrowDown } from 'Images/arrow-down.svg';
import { ReactComponent as _ArrowRight } from 'Images/arrow-right.svg';
import { ellipsisText } from 'Styles/styles';

export const Wrapper = styled.div`
  #vulns-general-add-assets {
    ${({ isFocused }) => isFocused && `
      background-color: white;
    `}
  }
`;
Wrapper.displayName = 'Wrapper';

export const StyledTitle = styled.div`
  font-size: 12.5px;
  font-weight: 500;
  line-height: 2.08;
  color: ${colors.darkBlueGrey};
  font-weight: 600;
  margin-bottom: 12px;
  ${({ required }) => required && `
    position: relative;
    &:after {
      content: '*';
      color: ${colors.redPink};
      position: absolute;
      top: 3px;
      margin-left: 3px;
    }
  `}  
`;
StyledTitle.displayName = 'StyledTitle';

export const DataContainer = styled.div`
  margin-top: 15px;
  max-height: 140px;
  overflow-y: auto;
`;
DataContainer.displayName = 'DataContainer';

export const GroupWrapper = styled.div`
  
`;
GroupWrapper.displayName = 'GroupWrapper';

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 14px;
  height: 34px;
  background-color: #dbe2e7;
`;
Header.displayName = 'Header';

export const GroupTitle = styled.div`
  text-align: left;
  font-size: 13.5px;
  line-height: 2.52;
  color: #1c2243;
  margin-right: auto;
  ${ellipsisText};
`;
GroupTitle.displayName = GroupTitle;

export const Arrow = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 10px;
  width: 10px;
`;
Arrow.displayName = 'Arrow';

export const ArrowDown = styled(_ArrowDown)`
  
`;
ArrowDown.displayName = 'ArrowDown';

export const ArrowRight = styled(_ArrowRight)`
  
`;
ArrowRight.displayName = 'ArrowRight';

export const ValuesContainer = styled.div`
  flex-direction: column;
  display: none;
  ${({ expanded }) => expanded && `
    display: flex;
  `}
`;
ValuesContainer.displayName = 'ValuesContainer';

export const Item = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: ${(props) => (!props.isOdd ? `${colors.iceBlue}` : '')};
  padding: 0 14px;
`;
Item.displayName = 'Item';

export const Value = styled.div`
  text-align: left;
  font-size: 13.5px;
  line-height: 2.52;
  margin-right: auto;
  color: #1c2243;
  ${ellipsisText};
`;
Value.displayName = 'Value';

export const TrashButton = styled.button`
  border: none;
  background-color: transparent;
`;
TrashButton.displayName = 'TrashButton';

export const Trash = styled(TrashIcon)` 
  width: 16px;
  height: 17px;
  align-self: flex-end;
  g > path { 
    fill: ${colors.blueGrey};
  }

`;
Trash.displayName = 'Trash';

export const Label = styled.div`
  display: flex;
  width: 100%;
`;
Label.displayName = 'Label';

export const Text = styled.div`
  text-align: left;
  font-size: 13.5px;
  margin-right: auto;
  width: 90px;
  ${ellipsisText}
  color: ${colors.darkBlueGrey};
`;
Text.displayName = 'Text';

export const Dash = styled(Text)`
  width: max-content;
`;
Dash.displayName = 'Dash';

export const ItemValues = styled.div`
  display: flex;
  width: 100%;
  height: 34px;
  align-items: center;
`;
ItemValues.displayName = 'ItemValues';
