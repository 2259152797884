import React from 'react';
import { Wrapper, Count, HomeIcon } from './styled';
import { useSelector } from 'react-redux';
import { selectAllVulnsCount } from 'store/Faraday/selectors';
import { generalView } from 'store/Tabs/selectors';
import formatNumber from 'Common/Functions/FormatNumber';

const VulnsCounter = () => {
  const allVulnsCount = useSelector(selectAllVulnsCount);
  const isInGeneralView = useSelector(generalView);

  const label = (allVulnsCount === 1) ? 'vulnerability' : 'vulnerabilities';

  return (isInGeneralView) && (
    <Wrapper>
      <HomeIcon />
      <Count>{ `${formatNumber(allVulnsCount)} ${label}` }</Count>
    </Wrapper>
  );
};

export default VulnsCounter;
