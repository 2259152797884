import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';

export const Wrapper = styled.div`
  display: block;
`;
Wrapper.displayName = 'Wrapper';

export const Edit = styled(EditIcon)`
    height: 17px;
`;

Edit.displayName = 'Edit';
