import React from 'react';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { Wrapper, Button } from './styled';

const CreateButton = ({ toggleSideBar }) => (
  <Wrapper>
    <Button title="Create" onClick={ () => toggleSideBar(true) }>
      <PlusIcon />
      Create Schedule
    </Button>
  </Wrapper>
);
export default CreateButton;
