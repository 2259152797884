import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDescription } from 'store/Jobs/actions';
import { selectJobDescription } from 'store/Jobs/selectors';
import { Wrapper, Label, Input } from './styled';

const Description = () => {
  const dispatch = useDispatch();
  const description = useSelector(selectJobDescription);

  const onChangeDebounce = debounce((value) => {
    dispatch(setDescription(value));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useCallback((value) => onChangeDebounce(value), []);

  const onChange = (e) => onChangeCallBack(e.target.value);

  return (
    <Wrapper>
      <Label>Description</Label>
      <Input defaultValue={ description } onChange={ onChange } />
    </Wrapper>
  );
};

export default Description;
