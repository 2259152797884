import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { showManageLeftFilters } from 'store/Manage/actions';
import { showHostLeftFilters } from 'store/Host/actions';
import { selectShowManageLeftFilters } from 'store/Manage/selectors';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import { selectIsFiltering } from 'store/Filters/selectors';
import IconButton from 'Common/Components/IconButton';
import { DisabledFilterIcon, EnabledFilterIcon } from './styled';
import { selectHostDetail, selectShowVulnsSidebarFilters } from 'store/Contextualization/AssetDetail/selectors';
import { redirectToHost, showVulnsSidebarFilters } from 'store/Contextualization/AssetDetail/actions';
import { selectShowRightFilters } from 'store/HostsGeneral/selectors';
import { selectShowManageRightFilters } from 'store/ManageGeneral/selectors';
import { showAssetsRightFilters } from 'store/HostsGeneral/actions';
import { showVulnsGeneralRightFilters } from 'store/ManageGeneral/actions';

const FilterSideBarIcon = ({ entity }) => {
  const dispatch = useDispatch();
  const isFiltering = useSelector((state) => selectIsFiltering(entity, state));
  const showFilters = useSelector((state) => {
    if (entity === 'vulns') return selectShowManageLeftFilters(state);
    if (entity === 'assets') return selectShowHostLeftFilters(state);
    if (entity === 'vulnsAssets') return selectShowVulnsSidebarFilters(state);
    if (entity === 'assetsGeneral') return selectShowRightFilters(state);
    if (entity === 'vulnsGeneral') return selectShowManageRightFilters(state);
    return false;
  });
  const hostDetail = useSelector(selectHostDetail);

  const setShowFilter = () => {
    if (entity === 'vulns') {
      dispatch(showManageLeftFilters(true, false));
    } else if (entity === 'assets') {
      if (hostDetail) dispatch(redirectToHost());
      dispatch(showHostLeftFilters(true));
    } else if (entity === 'vulnsAssets') {
      dispatch(showVulnsSidebarFilters(true));
    } else if (entity === 'assetsGeneral') {
      dispatch(showAssetsRightFilters(true));
    } else if (entity === 'vulnsGeneral') {
      dispatch(showVulnsGeneralRightFilters(true));
    }
  };

  const setHideFilter = () => {
    if (entity === 'vulns') dispatch(showManageLeftFilters(false, false));
    else if (entity === 'assets') dispatch(showHostLeftFilters(false));
    else if (entity === 'vulnsAssets') dispatch(showVulnsSidebarFilters(false));
    else if (entity === 'assetsGeneral') dispatch(showAssetsRightFilters(false));
    else if (entity === 'vulnsGeneral') dispatch(showVulnsGeneralRightFilters(false));
  };

  const handleClick = () => (showFilters ? setHideFilter() : setShowFilter());

  return (
    <>
      {!isFiltering && <IconButton icon={ <DisabledFilterIcon /> } title="Filters" onClick={ handleClick } />}
      {isFiltering && <IconButton icon={ <EnabledFilterIcon /> } title="Filters" onClick={ handleClick } />}
    </>
  );
};

FilterSideBarIcon.propTypes = {
  entity: PropTypes.string.isRequired
};

export default FilterSideBarIcon;
