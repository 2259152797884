import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  heigth: 100%;
  display: flex;
  margin-bottom: 20px;
`;
Wrapper.displayName = 'Wrapper';
export default Wrapper;
