/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectJobRules } from 'store/Jobs/selectors';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { RuleWrapper, OrLabel } from './styled';
import Rule from '../Rule';

const Rules = ({ highlight }) => {
  const rules = useSelector(selectJobRules);
  const intl = useIntl();
  const lastElement = rules.length - 1;

  return (
    <RuleWrapper>
      { rules.map((r, index) => (
        <div key={ `${JSON.stringify(r)}_wrapper` }>
          <Rule key={ `${JSON.stringify(r)}_rule` } rule={ r } highlight={ highlight } />
          { (index !== lastElement) && <OrLabel key={ `${JSON.stringify(r)}_concat` } children={ intl.formatMessage({ id: 'jobs.rules.concat' }) } /> }
        </div>
      )) }
    </RuleWrapper>
  );
};

Rules.propTypes = {
  highlight: PropTypes.bool
};

Rules.defaultProps = {
  highlight: false
};

export default Rules;
