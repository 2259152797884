import styled from 'styled-components';

export const AttributesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
  margin-right: auto;
  overflow: auto;
`;

AttributesWrapper.displayName = 'AttributesWrapper';

export const AndLabel = styled.div`
  user-select: none;
  text-align: left;
  margin: auto auto 11px 0px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
`;

AndLabel.displayName = 'AndLabel';
