/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Backdrop, DropdownContainer, OptionsContainer, Option, OptionLabel
} from './styled';

const Dropdown = ({
  show, onHide, onSelect, options, isFirst, offset, intlId, isDateType, isCustomField, isOneOfOp
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const onSelectOption = (option) => onSelect(option);
  const optionsContainerRef = React.useRef(null);
  const getOptionLabel = () => {
    if (isDateType) return 'Choose a date';
    else if (isOneOfOp) return 'Type one or more terms, separated by commas';
    else return 'Type your query';
  };

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, options.length - 1));
          break;
        case 'ArrowUp':
          setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
          break;
        case 'Enter':
          if (selectedIndex >= 0 && selectedIndex < options.length) {
            onSelectOption(options[selectedIndex]);
          }
          break;
        default:
          break;
      }

      if (optionsContainerRef.current) {
        const currentElement = optionsContainerRef.current.children[selectedIndex];
        const previousElement = optionsContainerRef.current.children[selectedIndex - 1];
        const nextElement = optionsContainerRef.current.children[selectedIndex + 1];

        if (e.key === 'ArrowDown' && previousElement) {
          previousElement.scrollIntoView({ block: 'start' });
        } else if (e.key === 'ArrowUp' && nextElement) {
          nextElement.scrollIntoView({ block: 'end' });
        } else {
          if (currentElement) currentElement.scrollIntoView({ block: 'nearest' });
        }
      }
    };

    if (show) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, selectedIndex, options]);

  if (!show) return null;
  return (
    <>
      <Backdrop onClick={ onHide } />
      <DropdownContainer isFirst={ isFirst } offset={ offset } isCustomField={ isCustomField }>
        <OptionsContainer ref={ optionsContainerRef }>
          {options.map((option, index) => (
            <Option
              key={ option }
              onClick={ () => onSelectOption(option) }
              style={ selectedIndex === index ? { backgroundColor: '#edf2f7' } : {} }
              title={ option }
            >
              <OptionLabel>
                { option && <FormattedMessage id={ `${intlId}.${option}` } defaultMessage={ isDateType ? moment(option).format('L') : option } /> }
              </OptionLabel>
            </Option>
          ))}
          {options.length === 0 && (
           <Option isOneOfOp={ isOneOfOp }>
              <OptionLabel isOneOfOp={ isOneOfOp }>
                { getOptionLabel() }
              </OptionLabel>
           </Option>
          )}
        </OptionsContainer>
      </DropdownContainer>
    </>
  );
};

Dropdown.propTypes = {
  offset: PropTypes.number,
  isFirst: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  intlId: PropTypes.string.isRequired,
  isCustomField: PropTypes.bool
};

Dropdown.defaultProps = {
  offset: 0,
  isFirst: false,
  show: false,
  options: [],
  isCustomField: false
};

export default Dropdown;
