import {
  SCHEDULE_FETCHING,
  SCHEDULE_FAIL,
  SCHEDULE_TABLE_GET_DATA,
  SCHEDULE_TABLE_SELECT_ROW,
  SCHEDULE_TABLE_RESET,
  GET_TOTAL_SCHEDULES,
  RUN_SCHEDULE
} from './constants';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  token: '',
  table: {
    list: {
      data: [],
      total: 0,
      page: 1,
      sortBy: 'id',
      sortDirection: 'asc',
      pageSize: 100
    },
    fields: {
      id: {
        order: 1, visible: false
      },
      description: {
        order: 2, visible: true
      },
      nextRun: {
        order: 3, visible: true
      },
      crontab: {
        order: 4, visible: true
      },
      agentName: {
        order: 5, visible: true
      },
      tool: {
        order: 6, visible: true
      },
      run: {
        order: 7, visible: true
      },
      active: {
        order: 8, visible: true
      }
    },
    selected: {
      rowsSelected: [], // filas seleccionadas
      lastRowIndexSelected: -1, // ultimo indice de fila seleccionada
      lastIndexForRange: -1, // ultimo rango de indice de fila seleccionada
      lastRowSelected: {} // ultima fila seleccionada.
    }
  },
  totalSchedules: 0 // corresponde a los schedules activos.
};

function schedule (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_TABLE_SELECT_ROW: {
      const {
        newRowsSelected, lastRowIndexSelected, indexForRange, rowSelected
      } = action.rowSelectedInfo;

      return {
        ...state,
        isFetching: false,
        error: false,
        table: {
          ...state.table,
          selected: {
            lastRowSelected: rowSelected,
            rowsSelected: newRowsSelected,
            lastRowIndexSelected: lastRowIndexSelected || -1,
            lastIndexForRange: indexForRange || -1
          }
        }
      };
    }

    case SCHEDULE_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
        error: false
      };
    }

    case SCHEDULE_TABLE_RESET: {
      return {
        ...state,
        table: {
          ...state.table,
          selected: initialState.table.selected
        }
      };
    }

    case SCHEDULE_TABLE_GET_DATA: {
      return {
        ...state,
        isFetching: false,
        error: false,
        table: {
          ...state.table,
          list: {
            ...state.table.list,
            data: action.data,
            total: action.data.length
          }
        }
      };
    }

    case SCHEDULE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        message: action.message
      };
    }

    case GET_TOTAL_SCHEDULES: {
      return {
        ...state,
        totalSchedules: action.totalSchedules
      };
    }
    case RUN_SCHEDULE: {
      return {
        ...state,
        running: action.id
      };
    }

    default: {
      return state;
    }
  }
}

export default schedule;
