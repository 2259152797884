import get from 'lodash/get';
import defaultFields from 'store/Services/fields';

// eslint-disable-next-line import/prefer-default-export
export const selectAssetServicesFields = (state) => {
  let fields = get(state, 'preferences.services_table', defaultFields);
  const excludedFields = ['asset', 'workspace_name'];

  fields = Object.keys(fields).reduce((filteredFields, field) => {
    if (!excludedFields.includes(field)) {
      filteredFields[field] = fields[field];
    }
    return filteredFields;
  }, {});

  return { ...fields };
};
