import BlueButton from 'Common/Components/BlueButton';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { newPipeline } from 'store/Pipelines/actions';
import { Wrapper, Title, EmptyImage } from './styled';

const Empty = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <EmptyImage />
      <Title>{ intl.formatMessage({ id: 'pipelines.empty.title' }) }</Title>
      <BlueButton label="Create a Pipeline" onClick={ () => dispatch(newPipeline()) } />
    </Wrapper>
  );
};
export default Empty;
