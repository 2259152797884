import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_SCHEDULER_OFFLINE_AGENT_WARNING } from 'store/modals/modals';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';

const OfflineAgent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'schedule.OfflineAgent.title' });
  const message = intl.formatMessage({ id: 'schedule.OfflineAgent.message' });
  const done = intl.formatMessage({ id: 'schedule.noWsModal.button' });

  const onDone = () => {
    dispatch(closeModal(MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));
  };

  return (
    <ConfirmNavigationModal
      title={ title }
      message={ message }
      onDone={ onDone }
      done={ done }
    />
  );
};

export default OfflineAgent;
