import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoSelection from 'Screens/Automation/Jobs/components/NoSelection';
import Empty from 'Screens/Automation/Jobs/components/Empty';
import { selectJobs, selectJobSelected } from 'store/Jobs/selectors';
import { newJob, viewJob } from 'store/Jobs/actions';
import Header from './components/Header';
import Name from './components/Name';
import Description from './components/Description';
import { Wrapper, Content } from './styled';
import ObjectAndAttributes from './components/ObjectAndAttributes';
import Tasks from './components/Tasks';

const Detail = ({ isNew, match }) => {
  const dispatch = useDispatch();
  const jobs = useSelector(selectJobs);
  const job = useSelector(selectJobSelected);

  useEffect(() => {
    const id = get(match, 'params.id', 0);
    if (isNew) dispatch(newJob());
    else if (id) {
      const jobFiltered = jobs.find((j) => j.id === parseInt(id, 10));
      dispatch(viewJob(jobFiltered));
    }
    return () => {
      if (!isEmpty(job)) dispatch(viewJob(null));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!isEmpty(job)) {
    return (
      <Wrapper key={ `detail-${job.id}` }>
        <Header />
        <Content>
          <Name />
          <Description />
          <ObjectAndAttributes />
          <Tasks />
        </Content>
      </Wrapper>
    );
  }
  if (jobs.length === 0) return <Empty />;

  return <NoSelection />;
};

Detail.propTypes = {
  isNew: PropTypes.bool.isRequired,
  match: PropTypes.instanceOf(Object).isRequired
};

export default Detail;
