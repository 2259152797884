import React, { useState } from 'react';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import IconButton from 'Common/Components/IconButton';
import EditColumnContext from 'Common/Components/EditColumnContext';
import { EditColumns } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';

const AddRemoveColumnsButton = ({ fields, setFieldVisibility, setNewOrderColumns, resetDefault }) => {
  const [showContext, toggleContext] = useState(false);

  const addRemoveColumns = (
    <EditColumnContext
      trigger="action-button-column"
      fields={ fields }
      toggle={ () => toggleContext(!showContext) }
      setFieldVisibility={ setFieldVisibility }
      setNewOrderColumns={ setNewOrderColumns }
      resetDefault={ resetDefault }
    />
  );

  return (
    <ContextMenuButton
      opened={ showContext }
      menu={ addRemoveColumns }
      trigger="action-button-column"
      display
      icon={ <IconButton icon={ <EditColumns /> } title="More" onClick={ () => {} } /> }
    />
  );
};

export default AddRemoveColumnsButton;
