import React, { useEffect } from 'react';
import { useFocus } from 'Common/Components/SearchBar/components/BasicFilter/functions';
import { PropTypes } from 'prop-types';
import { TagContainer, StyledInput, Wrapper } from './styled';

const AutocompleteInput = ({ inputVal, setInputVal }) => {
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    setInputFocus();
  }, [setInputFocus]);

  return (
    <Wrapper>
      <TagContainer>
        <StyledInput onChange={ ({ target }) => setInputVal(target.value) } value={ inputVal } ref={ inputRef } />
      </TagContainer>
    </Wrapper>
  );
};

AutocompleteInput.propTypes = {
  inputVal: PropTypes.string.isRequired,
  setInputVal: PropTypes.func.isRequired
};

export default AutocompleteInput;
