import React from 'react';
import PropTypes from 'prop-types';
import StyledTinyCircle from 'Common/Components/TinyCircle/styled';

const TinyCircle = ({ color, outlined }) => <StyledTinyCircle color={ color } outlined={ outlined } />;

TinyCircle.defaultProps = {
  outlined: false
};

TinyCircle.propTypes = {
  color: PropTypes.string.isRequired,
  outlined: PropTypes.bool
};

export default TinyCircle;
