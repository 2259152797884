/* eslint-disable react/no-children-prop */
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { removeRuleFromSelectedJob } from 'store/Jobs/actions';
import { PropTypes } from 'prop-types';
import { Wrapper, Title, TrashIcon } from './styled';

const Header = ({ rule }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Title children={ intl.formatMessage({ id: 'jobs.attributes.title' }) } />
      <TrashIcon onClick={ () => dispatch(removeRuleFromSelectedJob(rule)) } />
    </Wrapper>
  );
};

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired
};

export default Header;
