import styled from 'styled-components';
import colors from 'Styles/colors';
import emptyImage from 'Images/jobs_pipelines_empty.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d9e4ef;
  padding: 114px 70px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 15px;
  text-align: center;
  color: ${colors.blueGrey};
  margin-bottom: 22px;
`;
Title.displayName = 'Title';

export const EmptyImage = styled.img.attrs({
  src: emptyImage
})`
  width: 100%;
  height: auto;
  max-width: 460px;
  margin-bottom: 30px;
`;
EmptyImage.displayName = 'EmptyImage';
