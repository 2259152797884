import React from 'react';
import IconButton from 'Common/Components/IconButton';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { selectRowsSelected } from 'store/Schedule/selectors';
import { Wrapper, Edit } from './styled';

const EditButton = ({ toggleSideBar }) => {
  const rowsSelected = useSelector(selectRowsSelected);

  if (isEmpty(rowsSelected)) return null;
  return (
    <Wrapper>
      <IconButton title="Edit" onClick={ () => toggleSideBar(true) } icon={ <Edit /> } />
    </Wrapper>
  );
};

export default EditButton;
