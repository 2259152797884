/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { AndLabel, AttributesWrapper } from './styled';
import Attribute from './Attribute';

const Attributes = ({ rule }) => {
  const attributes = get(rule, 'attributes', []);
  const intl = useIntl();
  const lastElement = attributes.length - 1;

  return (
    <AttributesWrapper>
      {
        attributes.map((a, index) => (
          <div key={ `${JSON.stringify(a)}_wrapper` }>
            <Attribute rule={ rule } attribute={ a } />
            { (index !== lastElement) && <AndLabel children={ intl.formatMessage({ id: 'jobs.attributes.concat' }) } /> }
          </div>
        ))
      }
    </AttributesWrapper>
  );
};

Attributes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired
};

export default Attributes;
