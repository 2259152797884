/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { VULNS_CONTEXT_MENU_FILTER_KEYS } from 'store/Filters/constants';
import { setContextMenuFilter, setShowContextMenu } from 'store/ManageGeneral/actions';
import { FilterIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';

export const Filter = () => {
  const vulnsSelected = useSelector(selectVulnsSelected);
  const dispatch = useDispatch();
  const title = useIntl().formatMessage({ id: 'manage.cm.filter' });
  const isBulk = vulnsSelected.length > 1;
  const noVulnsSelected = vulnsSelected.length === 0;

  const setFilter = (filterName) => {
    dispatch(setContextMenuFilter(filterName, vulnsSelected[0]));
    dispatch(setShowContextMenu(false));
  };

  if (noVulnsSelected) return null;
  return (
    <ContextSubMenu
      title={ title }
      icon={ <FilterIcon /> }
      enabled={ !isBulk }
    >
      {
        VULNS_CONTEXT_MENU_FILTER_KEYS.filter((filter) => filter.label !== 'Asset').map((item) => <MenuItem key={ item.name } title={ item.label } onClick={ () => setFilter(item.name) } />)
      }
    </ContextSubMenu>
  );
};
