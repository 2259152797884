import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomAttributesModal from 'Screens/Contextualization/Manage/components/CustomAttributesModal';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import IconButton from 'Common/Components/IconButton';
import EditColumnContext from 'Common/Components/EditColumnContext';
import selectModal from 'store/modals/selectors';
import { MODAL_CUSTOM_ATTRIBUTES } from 'store/modals/modals';
import { EditColumns } from '../../styled';

const AddRemoveColumnsButton = ({ fields, setFieldVisibility, setNewOrderColumns, resetDefault }) => {
  const [showContext, toggleContext] = useState(false);
  const showCustomAttributesModal = useSelector((state) => selectModal(state, MODAL_CUSTOM_ATTRIBUTES));

  const addRemoveColumns = (
    <EditColumnContext
      trigger="action-button-column"
      fields={ fields }
      toggle={ () => toggleContext(!showContext) }
      setFieldVisibility={ setFieldVisibility }
      setNewOrderColumns={ setNewOrderColumns }
      resetDefault={ resetDefault }
    />
  );

  return (
    <>
      { showCustomAttributesModal && <CustomAttributesModal /> }
      <ContextMenuButton
        opened={ showContext }
        menu={ addRemoveColumns }
        trigger="action-button-column"
        display
        icon={ <IconButton icon={ <EditColumns /> } title="Columns" onClick={ () => {} } /> }
      />
    </>
  );
};

export default AddRemoveColumnsButton;
