import React from 'react';
import Wrapper from './styled';
import { useDispatch, useSelector } from 'react-redux';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { setJobTarget } from 'store/Jobs/actions';
import { selectTaskTarget } from 'store/Jobs/selectors';

const EntitySelector = () => {
  const dispatch = useDispatch();
  const target = useSelector(selectTaskTarget);

  const handleSelectAsset = (field, value) => {
    let target = '';
    if (value.name === 'Impacted Asset') {
      target = 'asset';
    }
    dispatch(setJobTarget(target, true));
  };

  const options = [{
    desc: 'Impacted Asset',
    name: 'Impacted Asset',
    value: 'asset'
  }, {
    desc: 'Filtered Vuln',
    name: 'Filtered Vuln',
    value: 'vuln'
  }];

  return (
    <Wrapper>
      <StandardDropdown
        width="200px"
        field="model"
        placeholder="Select entity"
        options={ options }
        updateValue={ handleSelectAsset }
        selectObject
        defaultValue={ (target === 'asset' && 'Impacted Asset') || 'Filtered Vuln' }
      />
    </Wrapper>
  );
};

export default EntitySelector;
