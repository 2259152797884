import styled from 'styled-components';
import colors from 'Styles/colors';
import { simpleSpinner } from 'Styles/effects';

// eslint-disable-next-line import/prefer-default-export
export const LinkAsButton = styled.button`
  color: ${colors.blueCerulean};
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  background: transparent;
  border: 0;
  padding: 0 5px;
  ${(props) => props.isFetching && simpleSpinner()};
`;
LinkAsButton.displayName = 'LinkAsButton';
