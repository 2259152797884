import RadioButton from 'Common/Components/RadioButton/index';
import styled, { css } from 'styled-components';
import colors from 'Styles/colors';

export const SetupSection = styled.div`
`;
SetupSection.displayName = 'SetupSection';

export const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${colors.black};
  padding-bottom: 14px;
`;
Subtitle.displayName = 'Subtitle';

export const DescriptionSchedule = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  line-height: 1.33;
  color: ${colors.greyBlue};
  padding-bottom: 21px;
`;
DescriptionSchedule.displayName = 'DescriptionSchedule';

export const FieldWrapper = styled.div`
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
  border: solid 1px #dbdbdb;
  padding: 22px 22px 0px 22px;
  margin-bottom: 20px;
  ${(props) => props.disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  &>:nth-child(4) {
    margin: 0 15px 11px 0;
  }
`;
FieldWrapper.displayName = 'FieldWrapper';

export const CustomRadioButton = styled(RadioButton)`
  color: ${colors.greyBlue};
  font-size: 12.5px;
`;
CustomRadioButton.displayName = 'CustomRadioButton';
