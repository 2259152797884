import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { allVulnsAreSelected, someVulnIsSelected } from 'store/ManageGeneral/selectors';
import { selectAllVulns } from 'store/ManageGeneral/actions';

const CheckHeaderVulnsGeneral = () => {
  const allVulnsSelected = useSelector(allVulnsAreSelected);
  const someVulnSelected = useSelector(someVulnIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(selectAllVulns());
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allVulnsSelected } partialChecked={ someVulnSelected && !allVulnsSelected } />
  );
};

export default CheckHeaderVulnsGeneral;
