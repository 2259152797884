import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowDetail } from 'store/ManageGeneral/selectors';
import { redirectToVulnerabilities } from 'store/ManageGeneral/actions';
import Title from '../Title';
import {
  Wrapper,
  CloseIconWrapper,
  CloseIcon
} from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Header/styled';

const Header = () => {
  const dispatch = useDispatch();
  const show = useSelector(selectShowDetail);

  if (!show) return null;
  return (
    <Wrapper>
      <Title />
      <CloseIconWrapper onClick={ () => dispatch(redirectToVulnerabilities()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
