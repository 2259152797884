import BlueButton from 'Common/Components/BlueButton';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveJob
} from 'store/Jobs/actions';
import { selectJobPipelines } from 'store/Jobs/selectors';
import {
  Wrapper, Title, Description, WrapperButtons, CancelButton, BlueText
} from './styled';

const SaveJobAlert = () => {
  const dispatch = useDispatch();
  const pipelines = useSelector(selectJobPipelines);
  return (
    <Wrapper>
      <Title>Job Editor</Title>
      <Description>
        This Job is used in
        <BlueText>{ `${pipelines.length} different pipelines` }</BlueText>
        . This change will affect their confinguration, you can alternatively save a new, different version.
      </Description>
      <WrapperButtons>
        <CancelButton onClick={ () => dispatch(saveJob(true)) }>Save as new</CancelButton>
        <BlueButton onClick={ () => dispatch(saveJob(false)) } label={ `Save and affect ${pipelines.length} pipelines` } />
      </WrapperButtons>
    </Wrapper>
  );
};
export default SaveJobAlert;
