import React from 'react';
import { Wrapper, Text, Link } from './styled';

const EmptySchedule = () => (
  <Wrapper>
    <Text>
      You don&apos;t have any Schedules yet, create one to get started or
      <Link href="https://docs.faradaysec.com/Scheduling/" target="_blank"> check our documentation</Link>
      .
    </Text>
  </Wrapper>
);

export default EmptySchedule;
