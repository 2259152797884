import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBulkUpdateValue } from 'store/Manage/actions';
import { selectBulkUpdateCustomAttribute, selectModalBulkUpdateValue, selectVulnsSelected } from 'store/Manage/selectors';
import get from 'lodash/get';
import { Dropdown } from 'Common/Components/Inputs';
import { parseOptions } from 'Screens/Contextualization/Manage/components/CustomAttributesModal/functions';
import { newDropdownStyle } from 'Common/styles/style';
import { Title, ChoiceWrapper } from '../../styled';
import { CustomOption } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/ChoiceCustomField';

const ChoiceInput = () => {
  const dispatch = useDispatch();
  const customAttribute = useSelector(selectBulkUpdateCustomAttribute);
  const onChange = (opt) => dispatch(setBulkUpdateValue(opt.value));
  const fieldName = get(customAttribute, 'field_display_name', '');
  const options = get(customAttribute, 'field_metadata', '');
  const optionsArray = parseOptions(options);
  const parsedOptions = optionsArray.map((option) => ({ label: option, value: option }));
  parsedOptions.unshift({ label: '(Empty)', value: '(Empty)' });
  const vulnsSelected = useSelector(selectVulnsSelected);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const value = ((vulnsSelected.length > 1) || (bulkUpdateValue === null)) ? '' : { label: bulkUpdateValue, value: bulkUpdateValue };

  return (
    <ChoiceWrapper>
      <Title children={ fieldName } />
      <Dropdown
        defaultValue={ value }
        field="option_field"
        options={ parsedOptions }
        onChange={ onChange }
        placeholder="Choose Option"
        style={ newDropdownStyle }
        components={ { Option: CustomOption } }
      />
    </ChoiceWrapper>
  );
};

export default ChoiceInput;
