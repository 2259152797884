import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { selectVulnRow } from 'store/ManageGeneral/actions';
import Checkbox from 'Common/Components/Checkbox';
import Skeleton from 'Common/Components/Skeleton';

const CheckColumnVulnsGeneral = ({ vuln, showSkeleton }) => {
  const vulnsSelected = useSelector(selectVulnsSelected);
  const dispatch = useDispatch();
  const isSelected = vulnsSelected.find((vulnSelected) => vulnSelected._id === vuln._id);
  const handleClick = (e) => dispatch(selectVulnRow(e, vuln));

  if (showSkeleton) return <Skeleton />;
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};

export default CheckColumnVulnsGeneral;
