import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { newGetVulns } from 'store/Manage/filterActions';
import { getVulnsGeneral } from 'store/ManageGeneral/actions';
import { getVulns } from 'store/Contextualization/AssetDetail/actions';
import { selectCurrentHost } from 'store/Host/selectors';

const RefreshButton = ({ entity }) => {
  const dispatch = useDispatch();

  const currentAsset = useSelector(selectCurrentHost);
  const hostIp = get(currentAsset, 'ip', '');

  const refresh = {
    vulns: () => dispatch(newGetVulns()),
    vulnsAssets: () => dispatch(getVulns(hostIp)),
    vulnsGeneral: () => dispatch(getVulnsGeneral())
  };

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ refresh[entity] } />
  );
};

export default RefreshButton;
