import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const Wrapper = styled.div`
  display: block;
`;
Wrapper.displayName = 'Wrapper';

export const Trash = styled(TrashIcon)`
    height: 17px;
`;

Trash.displayName = 'Trash';
