import React, { useState, useEffect } from 'react';
import DataContainer from 'Common/Components/Contextualization/InputFilter/components/DataContainer';
import {
  Wrapper, StyledTitle, InputWrapper, InputComp, ChevronDown,
  ListData, DataItem, Placeholder, TrashWrapper, Trash, DataItemWrapper,
  Text
} from './styled';
import { useDispatch } from 'react-redux';
import { setCreateVulnError } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';

const InputSearch = ({
  getObjects = null, onSelect, data, placeholder, id, title, required = false,
  disabled = false, addedItems, removeItem, errorMessage, errorTitle, fullWidth, height
}) => {
  const dispatch = useDispatch();
  const [inputValue, setValue] = useState('');
  const [showData, setShowData] = useState(false);
  const isOdd = (n) => n % 2 !== 0;

  const filteredData = data.filter((it) => inputValue === '' || it.name.toLowerCase().includes(inputValue.toLowerCase()));

  useEffect(() => {
    if (getObjects) getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (v) => {
    setValue(v);
    setShowData(true);
  };

  const onSelectHandler = (item) => {
    setValue('');
    setShowData(false);
    onSelect(item);
  };

  const onClickArrow = () => {
    if (disabled) {
      dispatch(setCreateVulnError(true, errorMessage, errorTitle));
    } else {
      setShowData(!showData);
    }
  };

  return (
    <Wrapper>
      <StyledTitle required={ required }>{ title }</StyledTitle>
      <InputWrapper onBlur={ () => setShowData(false) }>
        <InputComp
          value={ inputValue }
          placeholder={ placeholder }
          onChange={ (e) => handleChange(e.target.value) }
          id={ id }
          autoComplete="off"
          onFocus={ () => { if (disabled) dispatch(setCreateVulnError(true, errorMessage, errorTitle)); } }
        />
        <ChevronDown onClick={ onClickArrow } />
        { (filteredData.length > 0 && showData) &&
        <DataContainer filteredData={ filteredData } onSelectHandler={ onSelectHandler } fullWidth={ fullWidth } height={ height } />
      }
      </InputWrapper>

      <ListData>
      { addedItems && addedItems.map((item, i) => (
        <DataItem
          key={ `di_${Math.random().toString(36).substr(2, 9)}` }
          isOdd={ isOdd(i) }
        >
          { (item.type && item.type === 'Service')
            ? <DataItemWrapper>
                <Text title={ item.parent_name }>{item.parent_name}</Text>
                <Placeholder>-</Placeholder>
                <Text title={ item.name }>{item.name}</Text>
                <Placeholder>-</Placeholder>
                <Text title={ item.protocol }>{item.protocol}</Text>
              </DataItemWrapper>
            : <Placeholder>{item.name}</Placeholder>
          }

          <TrashWrapper
            onClick={ () => removeItem(item) }
          >
            <Trash />
          </TrashWrapper>
        </DataItem>
      )) }
      </ListData>
    </Wrapper>
  );
};

export default InputSearch;
