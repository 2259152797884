import React from 'react';
import { PropTypes } from 'prop-types';
import Button from './styled';

const ActionButton = ({ onClick, title, icon }) => (
  <Button title={ title } onClick={ onClick }>
    {icon}
  </Button>
);

ActionButton.defaultProps = {
  onClick: null
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

export default ActionButton;
