import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as DragHandle } from 'Images/icon_drag.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Box = styled.div`
 position: relative;
  width: 100%;
  min-height: 92px;
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 2px 15px 0 #e3e3e3;
  border: solid 1px ${colors.veryLightPink};
  margin-bottom: 30px;
`;
Box.displayName = 'Box';

export const BoxWrapper = styled.div`
  display: flex;
  padding: 10px 16px;
`;
BoxWrapper.displayName = 'BoxWrapper';

export const DragIcon = styled(DragHandle)`
  margin: 4px 16px 0 0;
`;
DragIcon.displayName = 'DragIcon';

export const TextWrapper = styled.div`
  width: 64%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;
TextWrapper.displayName = 'TextWrapper';

export const Name = styled.h3`
  font-size: 17px;
  font-weight: 600;
  color: ${colors.black};
  margin-bottom: 3px;
`;
Name.displayName = 'Name';

export const Description = styled.span`
  font-size: 12.5px;
  color: ${colors.greyBlue};
  margin-bottom: 11px;
`;
Description.displayName = 'Description';

export const Id = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: #264d6d;
`;
Id.displayName = 'Id';

export const EditActionBar = styled.div`
  position: absolute;
  right: 25px;
  top: 18px;
  display: flex;
  .create-button {
    width: 89px;
  }
`;
EditActionBar.ActionBar = 'EditActionBar';

export const CancelButton = styled.button`
  border: 1px solid #d4d7e5;
  color: ${colors.darkBlueGrey};
  background-color: white;
  margin-right: 13px;
  font-size: 14.5px;
  width: 89px;
  height: 34px;
  border-radius: 3px;
  font-weight: 400;
`;
CancelButton.displayName = 'CancelButton';
