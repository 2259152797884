import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import {
  setBulkUpdateField, setBulkUpdateValue, updateVulnGeneral
} from 'store/ManageGeneral/actions';
import { useIntl } from 'react-intl';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';

const EaseOfResolution = () => {
  const items = [
    { name: 'trivial', label: 'Trivial' },
    { name: 'simple', label: 'Simple' },
    { name: 'moderate', label: 'Moderate' },
    { name: 'difficult', label: 'Difficult' },
    { name: 'infeasible', label: 'Infeasible' }
  ];

  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;

  const onEaseOfResolutionEdit = (easeOfResolution) => {
    if (isBulk) {
      dispatch(setBulkUpdateField('easeofresolution'));
      dispatch(setBulkUpdateValue(easeOfResolution));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVulnGeneral(vulnsSelected[0], 'easeofresolution', easeOfResolution));
  };

  return (
    <ContextSubMenu
      title={ useIntl().formatMessage({ id: 'manage.cm.edit.item.easeOfResolution' }) }
    >
      {items.map((item) => <MenuItem key={ item.name } title={ item.label } onClick={ () => onEaseOfResolutionEdit(item.name) } />)}
    </ContextSubMenu>
  );
};

export default EaseOfResolution;
