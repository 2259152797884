import styled from 'styled-components';
import { ReactComponent as Run } from 'Images/icon-action-bar-trigger.svg';

export const WrapperColumn = styled.div`
  font-size: 13px;
  font-weight: 300;
  display: flex;
  height: 38px;
  align-items: center;
  text-transform: capitalize;
`;
WrapperColumn.displayName = 'WrapperColumn';

export default WrapperColumn;

export const RunIcon = styled(Run)`
`;
RunIcon.displayName = 'RunIcon';
