import React from 'react';
import Warning from 'Common/Components/Warning';
import { Wrapper, ModalWrapper } from 'Common/Components/ConfirmNavigationModal/styled';
import { StyledMessage } from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/CreationWarning/styled';
import { useDispatch, useSelector } from 'react-redux';
import useKeypress from 'Hooks/useEscKey';
import { selectErrorMessageCreateService, selectErrorTitleCreateService } from 'store/Services/selectors';
import { setCreateServiceError } from 'store/Services/actions';

const CreationWarning = () => {
  const dispatch = useDispatch();
  const errorTitle = useSelector(selectErrorTitleCreateService);
  const errorMessage = useSelector(selectErrorMessageCreateService);

  useKeypress('Escape', () => {
    dispatch(setCreateServiceError(false, '', ''));
  });

  return (
    <Wrapper>
      <ModalWrapper>
        <Warning
          title={ errorTitle }
          children={ <StyledMessage>{ errorMessage }</StyledMessage> }
          onClose={ () => dispatch(setCreateServiceError(false, '', '')) }
        />
      </ModalWrapper>
    </Wrapper>
  );
};

export default CreationWarning;
