import StandardDropdown from 'Common/Components/StandarDropdown';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectField, selectJobModel, selectTaskTarget } from 'store/Jobs/selectors';
import {
  Wrapper, String, Label, Boolean
} from './styled';
import Dropdown from '../../../ObjectAndAttributes/components/Rule/components/NewAttribute/components/Dropdown';
import isEmpty from 'lodash/isEmpty';
import { FieldsWrapper, Field } from 'Screens/Automation/Pipelines/components/Detail/components/JobSequence/JobItem/components/JobDetails/styled';

const Value = ({
  field, value, onChange, valDropdown, setValDropdown
}) => {
  const model = useSelector(selectJobModel);
  const target = useSelector(selectTaskTarget);
  const fieldModel = target || model;
  const fieldDetail = useSelector((state) => selectField(state, fieldModel, field));
  const refDropdown = useRef();
  const inputRef = useRef();
  const setFocus = () => { inputRef.current && inputRef.current.focus(); };
  const [showValuesSeparated, setShowValuesSeparated] = useState(false);
  const valuesList = value.includes(',') ? value.split(', ') : [];

  useEffect(() => {
    if (value) {
      setShowValuesSeparated(true);
      setValDropdown(false);
    }
    if (refDropdown.current && fieldDetail) refDropdown.current.scrollIntoView({ behavior: 'smooth' });
    if (inputRef.current && fieldDetail) {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusInput = () => {
    setValDropdown(true);
    if (refDropdown) refDropdown.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onSelect = () => {
    setValDropdown(false);
    setShowValuesSeparated(true);
  };

  const onClickValues = () => {
    setShowValuesSeparated(false);
    setValDropdown(true);
  };

  if (!fieldDetail) return null;

  const getFieldComponent = () => {
    if (get(fieldDetail, 'valid.length', 0) > 0) {
      return (
        <StandardDropdown
          width="200px"
          field="value"
          options={ fieldDetail.valid.map((value) => ({ desc: value, name: value, id: value })) }
          updateValue={ (f, item) => onChange(item.id) }
          defaultValue={ value }
          selectObject
        />
      );
    }
    if (fieldDetail.type === 'bool') return <Boolean defaultValue={ value } onClick={ (e) => { e.stopPropagation(); onChange(value === 'True' ? 'False' : 'True'); } }>{ !value || value === 'False' ? 'False' : 'True'}</Boolean>;
    if (fieldDetail.type === 'hostnames') {
      return (
      <>
      { showValuesSeparated
        ? <FieldsWrapper onClick={ onClickValues }>
          { !isEmpty(valuesList) && valuesList.map((v, i) => <Field key={ `${v}_${i}` } supportsList>{v}</Field>)}
          </FieldsWrapper>
        : <>
            <String ref={ inputRef } defaultValue={ value } onChange={ (e) => onChange(e.target.value) } onFocus={ focusInput } />
            <Dropdown show={ valDropdown } intlId="model.valid" onHide={ () => setValDropdown(false) } options={ !value ? [] : [value] } onSelect={ onSelect } isFirst isOneOfOp />
            <div ref={ refDropdown } />
          </>
      }
      </>
      );
    }
    return <String defaultValue={ value } onChange={ (e) => onChange(e.target.value) } />;
  };
  return (
    <Wrapper key={ `${model}-${field}` }>
      <Label>Value</Label>
      { getFieldComponent() }
    </Wrapper>
  );
};

Value.defaultProps = {
  value: ''
};

Value.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default Value;
