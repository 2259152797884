import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Line } from './styled';

const Step = ({ icon, last, select }) => (
  <Wrapper select={ select }>
    { select && <Line /> }
    { icon }
    <Line last={ last } />
  </Wrapper>
);

Step.defaultProps = {
  last: false,
  select: false
};
Step.propTypes = {
  icon: PropTypes.node.isRequired,
  last: PropTypes.bool,
  select: PropTypes.bool
};

export default Step;
