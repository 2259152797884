import styled from 'styled-components';
import colors from 'Styles/colors';

export const SelectWsSection = styled.div`
  
`;
SelectWsSection.displayName = 'SelectWsSection';

export const WrapperField = styled.div`
  
`;
WrapperField.displayName = 'WrapperField';

export const TextInput = styled.div`
  font-size: 17.5px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
  margin-bottom: 14px;
`;
TextInput.displayName = 'TextInput';

export const Label = styled.p`
  font-size: 12.5px;
  color: ${colors.greyBlue};
  padding-bottom: 21px;
`;
Label.displayName = 'Label';
