import React from 'react';
import { useIntl } from 'react-intl';
import CreateButton from './components/CreateButton';
import { Wrapper, Title } from './styled';

const Header = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'pipelines.list.title' }) }</Title>
      <CreateButton />
    </Wrapper>
  );
};

export default Header;
