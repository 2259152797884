import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFiltering, selectRowsPerPage } from 'store/Filters/selectors';
import { allVulnsAreSelected, selectSelectAll, selectVulnsCount, selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { Wrapper, Text, Blue, Bold } from 'Screens/Contextualization/Manage/components/ManageTable/components/SelecAll/styled';
import { selectTotalVulns, unSelectAllVulns, unselectTotalVulns } from 'store/ManageGeneral/actions';

const SelectAll = () => {
  const dispatch = useDispatch();
  const allVisualVulnsSelected = useSelector(allVulnsAreSelected);
  const vulnPageLimit = useSelector((state) => selectRowsPerPage('vulnsGeneral', state));
  const selectAll = useSelector(selectSelectAll);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnCount = useSelector(selectVulnsCount);
  const isFiltering = useSelector(state => selectIsFiltering('vulnsGeneral', state));

  const handleSelectAll = () => {
    dispatch(selectTotalVulns());
  };
  const handleClear = () => {
    dispatch(unselectTotalVulns());
    dispatch(unSelectAllVulns());
  };

  const content = () => {
    if (selectAll) {
      return (
            <Text>
        All <Bold>{vulnCount}</Bold> Vulnerabilities {isFiltering ? 'on this search' : ''} are selected. <Blue onClick={ handleClear }>Clear Selection</Blue>
            </Text>
      );
    } else if (isFiltering) {
      return (
<Text>
        All <Bold>{vulnsSelected.length}</Bold> Vulnerabilities on this page are selected. <Blue onClick={ handleSelectAll }>Select all Vulnerabilities that match this search.</Blue>
</Text>
      );
    } else {
      return (
<Text>
        All <Bold>{vulnsSelected.length}</Bold> Vulnerabilities on this page are selected. <Blue onClick={ handleSelectAll }>Select all {vulnCount} Vulnerabilities.</Blue>
</Text>
      );
    }
  };
  if (!allVisualVulnsSelected) return null;
  if (vulnsSelected.length < vulnPageLimit || vulnCount <= vulnPageLimit) return null;
  return <Wrapper>
        {content()}
         </Wrapper>;
};

export default SelectAll;
