import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 508px;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';
