import React from 'react';
import Warning from 'Images/warning-delete.svg';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectModalBulkUpdateField, selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import {
  Wrapper, Icon, Title
} from './styled';

const BulkTitle = () => {
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const selectedVulnsCount = vulnsSelected.length;

  const getTitle = () => {
    if (bulkUpdateField === 'name') return 'vulns.edit.title.name';
    else if (selectedVulnsCount > 1) return 'vulns.edit.bulkTitle';
    else return 'vulns.edit.title';
  };

  const title = getTitle();

  return (
    <Wrapper isBulk={ selectedVulnsCount > 1 }>
      <Icon src={ Warning } />
      <Title>
        <FormattedMessage id={ title } />
      </Title>
    </Wrapper>
  );
};

export default BulkTitle;
