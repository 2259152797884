import React from 'react';
import { useSelector } from 'react-redux';
import { getRequestUrl } from 'services/api/connector';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import copyToClipboard from 'utils/clipboard';
import { CopyApiLinkWrapper, CopyApiLinkIcon } from './styled';

const CopyApiLink = ({ assetsGeneral }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyApiLink' });
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const hostSelected = useSelector((state) => get(state, 'assetDetail.host', {}));
  const hostId = hostSelected._id;

  const copyApiLink = () => {
    if (hostId && workspaceSelected) {
      const hostPath = assetsGeneral ? `hosts/${hostId}` : `ws/${workspaceSelected}/hosts/${hostId}`;
      const link = getRequestUrl(hostPath);
      copyToClipboard(link);
    }
  };

  return (
    <CopyApiLinkWrapper title={ title } onClick={ copyApiLink }>
      <CopyApiLinkIcon />
    </CopyApiLinkWrapper>
  );
};

export default CopyApiLink;
