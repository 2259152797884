import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import upperCase from 'lodash/upperCase';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectJobModel } from 'store/Jobs/selectors';
import ActionSelector from '../ActionSelector';
import FieldSelector from '../FieldSelector';
import Value from '../Value';
import { Wrapper, TrashIcon } from './styled';

const TaskItem = ({
  index, command, field, value, onChange, onRemove
}) => {
  const model = useSelector(selectJobModel);
  const [valDropdown, setValDropdown] = useState(false);

  const handleActionChange = (item) => {
    onChange(index, upperCase(item.id), '', '');
  };

  const handleFieldChange = (item) => {
    onChange(index, command, item.id, '');
    setValDropdown(true);
  };

  const onChangeDebounce = debounce((newValue) => {
    onChange(index, command, field, newValue);
  }, 300);

  const handleValueChange = (newValue) => onChangeDebounce(newValue);
  return model && (
    <Wrapper>
      <ActionSelector value={ command } onChange={ handleActionChange } />
      { command && command !== 'delete' && <FieldSelector field={ field } onChange={ handleFieldChange } command={ command } />}
      { field && command !== 'delete' && <Value field={ field } value={ value } onChange={ handleValueChange } valDropdown={ valDropdown } setValDropdown={ setValDropdown } /> }
      <TrashIcon onClick={ onRemove } />
    </Wrapper>
  );
};

TaskItem.defaultProps = {
  command: '',
  field: '',
  value: ''
};

TaskItem.propTypes = {
  index: PropTypes.number.isRequired,
  command: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default TaskItem;
