export const PIPELINES_FETCHING = 'PIPELINES_FETCHING';
export const PIPELINES_FAIL = 'PIPELINES_FAIL';
export const PIPELINES_TABLE_GET_DATA = 'PIPELINES_TABLE_GET_DATA';
export const FILTER_PIPELINES = 'FILTER_PIPELINES';
export const PIPELINE_SELECTED = 'PIPELINE_SELECTED';
export const CREATE_PIPELINE = 'CREATE_PIPELINE';
export const PIPELINE_ENABLE_DISABLE = 'PIPELINE_ENABLE_DISABLE';
export const PIPELINE_UPDATE_FAIL = 'PIPELINE_UPDATE_FAIL';
export const RUN_PIPELINE = 'RUN_PIPELINE';
export const RUN_PIPELINE_FAIL = 'RUN_PIPELINE_FAIL';
export const SET_PIPELINE_INPUT_FIELD = 'SET_PIPELINE_INPUT_FIELD';
export const PIPELINE_CREATE_UPDATE_START = 'PIPELINE_CREATE_UPDATE_START';
export const PIPELINE_CREATE_UPDATE_SUCCESS = 'PIPELINE_CREATE_UPDATE_SUCCESS';
export const RESET_STATE_PIPELINE_CREATE_UPDATE = 'RESET_STATE_PIPELINE_CREATE_UPDATE';
export const SET_JOB = 'SET_JOB';
export const DELETE_JOB_FROM_PIPELINE = 'DELETE_JOB_FROM_PIPELINE';
export const SET_JOBS_NEW_ORDER = 'SET_JOBS_NEW_ORDER';
export const SET_TARGET = 'SET_TARGET';
export const SET_PIPELINE_REDIRECT_TO = 'SET_PIPELINE_REDIRECT_TO';
export const GET_JOBS = 'GET_JOBS';
export const OPEN_NEW_PIPELINE = 'OPEN_NEW_PIPELINE';
export const PIPELINES_RESET_ERROR = 'PIPELINES_RESET_ERROR';
