import React, { useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { filterPipelines } from 'store/Pipelines/actions';
import {
  Wrapper, Input, Button, SearchIcon
} from './styled';

const Search = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filterPipelines(''));
  }, [dispatch]);

  const onChangeDebounce = debounce((value) => {
    dispatch(filterPipelines(value));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useCallback((value) => onChangeDebounce(value), []);

  const onChange = (e) => onChangeCallBack(e.target.value);

  return (
    <Wrapper>
      <Input placeholder="Search" onChange={ onChange } />
      <Button>
        <SearchIcon />
      </Button>
    </Wrapper>
  );
};

export default Search;
