import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Contextualization/Manage/components/CustomAttributesModal/constants';
import BlueButton from 'Common/Components/BlueButton';
import OutlinedButton from 'Common/Components/OutlinedButton';

import { Wrapper, Buttons, Content, Label } from './styled';
import { Dropdown } from 'Common/Components/Inputs';
import { newDropdownStyle } from 'Common/styles/style';

export const CustomOption = ({ innerRef, innerProps, label, isFocused, isSelected }) => {
  return (
    <Label title={ label } isFocused={ isFocused } isSelected={ isSelected } ref={ innerRef } { ...innerProps }>
      { label }
    </Label>
  );
};

const ChoiceCustomField = ({
  field, onCancel, onSave, vuln, initialValue
}) => {
  const [options, setOptionsList] = useState([{ label: '', value: '' }]);
  const [selectedOption, setSelectedOption] = useState('');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    const list = field && field.field_metadata ? field.field_metadata.replace(/[\[\]']+/g, '').replaceAll('"', '').split(',') : [];
    const formattedList = list.map((option) => ({ label: option, value: option }));
    formattedList.unshift({ label: '(Empty)', value: '(Empty)' });
    setOptionsList(formattedList);
  }, [field]);

  useEffect(() => {
    if (selectedOption !== initialValue) {
      setSelectedOption(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vuln]);

  const handleSave = () => {
    if (selectedOption === '(Empty)') onSave(vuln, field.field_name, '');
    else onSave(vuln, field.field_name, selectedOption);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    if (initialValue || initialValue.length === 0) {
      setSelectedOption(initialValue);
    } else {
      onCancel();
    }
  };

  const onSelectType = (option) => {
    setSelectedOption(option.value);
    setEditMode(true);
  };

  return (
    <Wrapper>
      {(editMode || !initialValue) &&
      (
        <Dropdown
          field="option_field"
          options={ options }
          onChange={ onSelectType }
          defaultValue={ selectedOption }
          placeholder="Choose Option"
          style={ newDropdownStyle }
          components={ { Option: CustomOption } }
        />
      )}
      {!editMode && initialValue &&
        <Content onClick={ () => setEditMode(true) }>{initialValue}</Content>}
      {editMode && selectedOption &&
        (
        <Buttons>
          <OutlinedButton label="Cancel" onClick={ handleCancel } />
          <BlueButton label="Save" onClick={ handleSave } />
        </Buttons>
        )}
    </Wrapper>
  );
};

ChoiceCustomField.defaultProps = {
  initialValue: ''
};

ChoiceCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.CHOICE]),
    field_metadata: PropTypes.string.isRequired
  }).isRequired,
  vuln: PropTypes.shape({
    _id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.string
};

export default ChoiceCustomField;
