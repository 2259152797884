import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Empty from 'Screens/Automation/Pipelines/components/Empty';
import NoSelection from 'Screens/Automation/Pipelines/components/NoSelection';
import {
  selectPipelines, selectIsCreating, selectIsEditing, selectPipelineSelected
} from 'store/Pipelines/selectors';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { newPipeline, viewDetail } from 'store/Pipelines/actions';
import Header from './components/Header';
import JobSequence from './components/JobSequence';
import Target from './components/Target';
import { Wrapper, Content } from './styled';
import TextInput from './components/TextInput';

const Detail = ({ isNew, match }) => {
  const dispatch = useDispatch();
  const pipelines = useSelector(selectPipelines);
  const isCreating = useSelector((state) => selectIsCreating(state));
  const isEditing = useSelector((state) => selectIsEditing(state));
  const pipelineSelected = useSelector(selectPipelineSelected);
  const showDetail = isCreating || isEditing;

  useEffect(() => {
    const id = get(match, 'params.id', 0);
    if (isNew) dispatch(newPipeline(true));
    else if (id) {
      dispatch(viewDetail(pipelineSelected, true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      {(isEmpty(pipelines) && !isCreating) && <Empty />}
      {showDetail && (
        <Wrapper>
          <Header />
          <Content>
            <TextInput inputType="name" />
            <TextInput inputType="description" />
            <JobSequence />
            <Target />
          </Content>
        </Wrapper>
      )}
      { (!showDetail && !isEmpty(pipelines)) && <NoSelection /> }

    </>
  );
};

Detail.propTypes = {
  isNew: PropTypes.bool.isRequired,
  match: PropTypes.instanceOf(Object).isRequired
};

export default Detail;
