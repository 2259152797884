import styled from 'styled-components';
import colors from 'Styles/colors';

const Redirect = styled.div`
  font-size: 12.5px;
  font-weight: 600;
  color: ${colors.blueCerulean};
  display: inline-block;
  cursor: pointer;
`;
Redirect.displayName = 'Redirect';

export default Redirect;
