import { useRef, useState, useEffect } from 'react';
import { SEARCH_BY_ENTITIES } from 'store/Filters/entitiesConstants';
import { redirect } from 'store/Router/actions';
import { selectPathname } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

export function shouldRedirect (selectedEntityPathname) {
  return (dispatch, getState) => {
    const pathname = selectPathname(getState());
    const selectedWorkspace = selectCurrentWorkspace(getState());
    const currentLocationEntity = SEARCH_BY_ENTITIES.find((e) => pathname.includes(e.pathname));
    const selectedEntity = SEARCH_BY_ENTITIES.find((e) => selectedEntityPathname.includes(e.pathname));
    if (selectedEntityPathname && (currentLocationEntity.pathname !== selectedEntityPathname)) {
      let url = '';
      if (selectedEntity.wsDependant) url = `/${selectedEntityPathname}/${selectedWorkspace}`;
      else url = `/${selectedEntityPathname}`;
      dispatch(redirect(url));
    }
  };
}

export const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };
  const setBlur = () => { htmlElRef.current && htmlElRef.current.blur(); };
  return [htmlElRef, setFocus, setBlur];
};

export const filterByInput = (options, inputValue, entities = []) => {
  const filterCriteria = (option) => (
    (option.name.value.toLowerCase().includes(inputValue.toLowerCase()) ||
    option.name.label.toLowerCase().includes(inputValue.toLowerCase())) &&
    (entities.length === 0 ||
      (entities.length !== 0 &&
      !entities.includes(option.name.value.toLowerCase())))
  );

  const filterResult = options.filter(filterCriteria);
  if (filterResult.length > 0) return filterResult;
  return options;
};
