import useKeypress from 'Hooks/useEscKey';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';
import Wrapper from './styled';

const useSideBar = (Element, id) => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => (selectModal(state, id)));

  useKeypress('Escape', () => {
    dispatch(closeModal(id));
  }, [id]);

  const show = () => {
    dispatch(openModal(id));
  };

  const hide = () => {
    dispatch(closeModal(id));
  };

  /* eslint-disable react/jsx-props-no-spreading */
  const SidaBar = (props) => (showModal ? <Wrapper><Element { ...props } /></Wrapper> : null);

  return [SidaBar, show, hide];
};

export default useSideBar;
