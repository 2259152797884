import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomList from 'Common/Components/CustomList';
import { selectModalBulkUpdateField, selectModalBulkUpdateValue } from 'store/ManageGeneral/selectors';
import { addBulkUpdateValue, removeBulkUpdateValue } from 'store/ManageGeneral/actions';

const ItemList = () => {
  const [newItem, setNewItem] = useState('');
  const dispatch = useDispatch();
  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);

  const addItem = () => {
    if (newItem) {
      dispatch(addBulkUpdateValue(newItem));
      setNewItem('');
    }
  };

  return (
    <CustomList
      key={ `CUSTOM_LIST_${bulkUpdateField}` }
      placeholder={ `Add ${bulkUpdateField}` }
      addItem={ addItem }
      removeItem={ (index) => dispatch(removeBulkUpdateValue(bulkUpdateValue[index])) }
      setItem={ setNewItem }
      listData={ bulkUpdateValue }
      responsive
    />
  );
};

export default ItemList;
