import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { closeModal } from 'store/modals/actions';
import { MODAL_RUN_PIPELINE } from 'store/modals/modals';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { runPipeline } from 'store/Pipelines/actions';
import {
  Wrapper, Title, Message, Workspace, Footer
} from './styled';

const Run = ({ id, workspace }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(closeModal(MODAL_RUN_PIPELINE));
  }, [dispatch]);

  const close = () => dispatch(closeModal(MODAL_RUN_PIPELINE));

  const handleClickRunPipeline = () => {
    close();
    dispatch(runPipeline(id));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'pipelines.run.title' }) }</Title>
      <Message>
        This Pipeline will run on the historical data of
        {' '}
        <Workspace>{workspace}</Workspace>
        .
        This will affect the contents of the workspace and can’t be undone. Are you sure?
      </Message>
      <Footer>
        <Cancel onClick={ close }>{ intl.formatMessage({ id: 'pipelines.run.cancelButton' }) }</Cancel>
        <Accept onClick={ () => handleClickRunPipeline(id) }>{ intl.formatMessage({ id: 'pipelines.run.runButton' }) }</Accept>
      </Footer>
    </Wrapper>
  );
};

Run.propTypes = {
  id: PropTypes.number.isRequired,
  workspace: PropTypes.string.isRequired
};

export default Run;
