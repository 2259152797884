/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { showVulnDetailGeneral, setShowContextMenu } from 'store/ManageGeneral/actions';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import { EvidenceIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';

export const AddNewEvidence = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const isBulk = vulnsSelected.length > 1;

  if (isBulk) return null;
  return (
    <MenuItem
      title={ intl.formatMessage({ id: 'manage.cm.addNewEvidence' }) }
      icon={ <EvidenceIcon /> }
      onClick={ () => {
        if (selectedVulnID) dispatch(showVulnDetailGeneral(selectedVulnID, 'evidence'));
        dispatch(setShowContextMenu(false));
      } }
    />
  );
};
