import React from 'react';
import { Route, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { selectLocation } from 'store/Router/selectors';
import { checkWorkspace } from '../Screens/Faraday/actions/Actions';

const AppRoute = ({
  exact, path, component, edit, isNew
}) => {
  const Component = component;
  const dispatch = useDispatch();

  const location = useSelector((state) => selectLocation(state));
  const match = matchPath(location.pathname, {
    path,
    exact: true,
    strict: false
  });
  const ws = get(match, 'params.ws', '');
  const params = get(match, 'params', {});

  return (
    <Route exact={ exact } path={ path } key={ path } render={ (props) => <Component { ...props } edit={ edit } isNew={ isNew } params={ params } /> } onEnter={ dispatch(checkWorkspace(ws)) } />
  );
};

export default AppRoute;
