import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectCurrentHost } from 'store/Host/selectors';
import CustomActionBar from './CustomActionBar';
import VulnsTable from './VulnsTable';
import { Wrapper, TableContainer } from './styled';
import { selectShowDetail, selectError, selectErrorMessage, selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import VulnerabilityDetail from './VulnerabilityDetail';
import {
  hideManageDetail, loadManageDetail, resetState,
  clearError, getVulnsByAssetId
} from 'store/Contextualization/AssetDetail/actions';
import { MODAL_APPLY_TEMPLATE } from 'store/modals/modals';
import selectModal from 'store/modals/selectors';
import TemplateModal from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/TemplateModal';
import ApplyTemplateModal from 'Screens/Contextualization/KnowledgeBase/components/EditVuln';
import ErrorDialog from 'Common/Components/ErrorDialog';
import FilterSideBar from 'Screens/Contextualization/Manage/components/FilterSideBar';
import { getData } from 'store/Host/actions';
import { AssetVulnsTour } from 'Common/Components/Contextualization/Tour';
import VulnsContextMenu from './VulnsTable/ContextMenu';
import { getCustomAttributes } from 'store/CustomAttributes/actions';

const HostVulnerabilities = ({ params }) => {
  const currentHost = useSelector(selectCurrentHost);
  const dispatch = useDispatch();
  const showDetail = useSelector((state) => selectShowDetail('vulns', state));
  const showTemplateModal = useSelector((state) => selectModal(state, MODAL_APPLY_TEMPLATE));
  const error = useSelector((state) => selectError('vulns', state));
  const errorMessage = useSelector((state) => selectErrorMessage('vulns', state));
  const assetId = get(currentHost, 'id', 0);
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));

  const handleParamId = useCallback(() => {
    const id = get(params, 'vulnId', 0);
    if (parseInt(id, 10) > 0) dispatch(loadManageDetail(id));
    else dispatch(hideManageDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    const id = get(params, 'id', 0);
    if ((!assetId) && id) {
      dispatch(getData());
      dispatch(getVulnsByAssetId(id));
    }
    dispatch(getCustomAttributes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    dispatch(resetState('vulns'));
  }, [dispatch]);

  return (
    <Wrapper>
      <CustomActionBar />
      <TableContainer>
        <VulnsTable />
        <FilterSideBar entity="vulnsAssets" />
        { showDetail && <VulnerabilityDetail /> }
        <TemplateModal />
        { showTemplateModal && <ApplyTemplateModal entity="vulnsAssets" vulnsSelected={ vulnsSelected } /> }
        <VulnsContextMenu />
      </TableContainer>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError('vulns')) } /> : null }
      <AssetVulnsTour />
    </Wrapper>
  );
};

export default HostVulnerabilities;
