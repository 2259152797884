import React from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { redirectToServiceDetailGeneral, selectServiceRow, unSelectAllServices } from 'store/ServicesGeneral/actions';
import { NameWrapper, Name } from './styled';
import Skeleton from 'Common/Components/Skeleton';

const NameCell = ({ rowInfo, showSkeleton }) => {
  const serviceName = get(rowInfo, 'name', '');
  const serviceId = get(rowInfo, '_id', 0);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    dispatch(unSelectAllServices());
    dispatch(redirectToServiceDetailGeneral(serviceId));
    dispatch(selectServiceRow(e, rowInfo));
  };

  if (showSkeleton) return <Skeleton />;
  return (
    <NameWrapper>
      <Name onClick={ handleClick }>{ serviceName }</Name>
    </NameWrapper>
  );
};

export default NameCell;
