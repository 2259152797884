import GitInfo from 'react-git-info/macro';
import api from 'services/api';
import { WORKSPACE_SELECTION } from 'Screens/Wizard/constants/index';
import {
  workspaceSelectedForWorking,
  setUrlReturn
} from 'Screens/Faraday/actions/Actions';
import { getWorkspaces, getWorkspaceStats, setWsStatsError, WORKSPACE_SELECTED_FOR_WORKING } from 'Screens/Workspaces/actions/Actions';
import { toggleAutoSelectWs } from 'Screens/Wizard/actions/Actions';
import Cookies from 'universal-cookie';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { SAVE_PREFERENCES_SUCCESS, updatePreferences } from 'store/Preferences/actions';
import { selectAutoSelectWs, selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import { selectIsLoggedIn, selectRememberMe, selectUserRoles } from 'store/Sesion/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { selectLocation, selectPathname } from 'store/Router/selectors';
import { redirect } from 'store/Router/actions';
import { selectUrlReturn } from 'store/Faraday/selectors';
import { setError } from 'store/errors/actions';
import { GET_WS_STATS_START, GET_WS_STATS_SUCCESS } from 'Screens/Dashboard/actions/Actions';
import { removeUnfinishedCommands, showProcessingQueue } from 'Common/Components/FileUploadContext/actions';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_FETCH_FINISHED = 'LOGIN_FETCH_FINISHED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const HIDE_ERROR_MESSAGE_LOGIN = 'HIDE_ERROR_MESSAGE_LOGIN';
export const LOGOUT_SUCCESS_REDIRECT = 'LOGOUT_SUCCESS_REDIRECT';
export const NOT_LOGGED = 'NOT_LOGGED';
export const LOAD_PREFERENCES = 'LOAD_PREFERENCES';
export const SET_INITIAL_STEP_WIZARD = 'SET_INITIAL_STEP_WIZARD';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';
export const LOGIN_TO_INACTIVE_WORKSPACE_ERROR = 'LOGIN_TO_INACTIVE_WORKSPACE_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VALIDATING_SESSION_START = 'VALIDATING_SESSION_START';
export const VALIDATING_SESSION_END = 'VALIDATING_SESSION_END';

/* Callbacks */

function failureLoginCallback () {
  return (dispatch) => {
    dispatch({ type: LOGIN_FAIL, data: { message: 'An error has occurred.' } });
  };
}

export function failureSessionValidationCallback () {
  return (dispatch) => {
    dispatch({ type: NOT_LOGGED });
  };
}

export function startWizard (workspaces) {
  return (dispatch) => {
    dispatch({
      type: SET_INITIAL_STEP_WIZARD, step: WORKSPACE_SELECTION, workspaces, width: '450px', height: '506px'
    });
    dispatch(redirect('/wizard', false));
  };
}

function validateAutoLoginWs (workspaceName, legacyUI) {
  return async (dispatch, getState) => {
    const { workspaces } = getState().workspace;
    const workspaceToLogIn = workspaces.find((ws) => ws.name === workspaceName);

    const goToWsSelector = (legacyUI) => {
      if (legacyUI) dispatch(startWizard(workspaces));
      else dispatch(redirect('/workspaces'));
    };

    if (workspaceToLogIn) {
      const isActive = get(workspaceToLogIn, 'active', false);
      if (isActive) {
        dispatch({ type: GET_WS_STATS_START });
        dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: workspaceName, redirect: true });
        dispatch(redirect(`/feed/${workspaceName}`));
        try {
          const response = await api.workspace.getWsStats(workspaceName);
          dispatch(getWorkspaceStats(response));
          dispatch({ type: GET_WS_STATS_SUCCESS });
        } catch (e) {
          dispatch(setWsStatsError(e.message));
        }
      } else {
        dispatch(toggleAutoSelectWs(false));
        dispatch({ type: LOGIN_TO_INACTIVE_WORKSPACE_ERROR, errorMessage: 'Attempted to log into an inactive Workspace' });
        goToWsSelector(legacyUI);
      }
    } else {
      dispatch(toggleAutoSelectWs(false));
      goToWsSelector(legacyUI);
    }
  };
}

export function setUser (user, roles, csrfToken) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESS, user, roles, csrfToken
    });
  };
}

function successSessionCallback (data) {
  return async (dispatch) => {
    const gitInfo = GitInfo();
    const config = await api.faraday.fetchApiConfig();

    let licenseStatus;
    try {
      licenseStatus = await api.faraday.getLicense(config);
    } catch (e) {
      setError('There was an error while fetching licence config');
    }

    await dispatch({
      type: LOAD_PREFERENCES,
      permissions: data.permissions,
      hash: gitInfo.commit.shortHash,
      apiVersion: config.ver,
      role: data.roles[0],
      email: config.email,
      userName: data.name,
      wsPort: config.websocket_port,
      licenseStatus
    });

    await dispatch({ type: SAVE_PREFERENCES_SUCCESS, preferences: data.preferences || {} });

    await dispatch(setUser(data.name, data.roles, data.csrf_token));

    // obtengo workspaces, esto se usa en todos lados.
    await dispatch(getWorkspaces(false, true));
  };
}

export function createSession () {
  return async (dispatch) => {
    try {
      const data = await api.faraday.getSession();
      await dispatch(successSessionCallback(data));
    } catch (e) {
      await dispatch(failureSessionValidationCallback());
    }
  };
}

export function setFirstLogin () {
  return async (dispatch) => {
    await dispatch({ type: SET_FIRST_LOGIN, firstLogin: true });
    await dispatch(workspaceSelectedForWorking('faraday_beta'));
    // dispatch(redirect('/wizard', false));
    dispatch(redirect('/workspaces'));
  };
}

export function loginRedirect () {
  return async (dispatch, getState) => {
    // const autoSelect = selectAutoSelectWs(getState()); // opcion autoselec, true|false
    // const workspaceActivity = selectWorkspaceActivity(getState()); // ultimos workspaces usados
    const workspaces = selectActiveWorkspaces(getState()); // listado de todos los workspaces
    const roles = selectUserRoles(getState()); // roles del usuario
    const urlReturn = selectUrlReturn(getState());
    const pathname = selectPathname(getState());
    // const showLegacyUI = selectShowLegacyUI(getState());

    if (urlReturn && urlReturn !== '/login' && urlReturn !== '/logout' && urlReturn !== '/') {
      dispatch(redirect(urlReturn));
      dispatch(setUrlReturn(''));
    } else if (includes(roles, 'admin') && workspaces.length === 0) {
      // setea el primer login, y muestra workspace por default.
      dispatch(setFirstLogin());
    } else if (pathname === '/') {
      dispatch(redirect('/workspaces'));
    } else {
      dispatch(redirect('/workspaces'));
    }
    // } else if (autoSelect && !isEmpty(workspaceActivity)) {
    //   dispatch(redirect('/pick-version'));
    // } else if ((pathname === '/') && !showLegacyUI) {
    //   dispatch(redirect('/workspaces'));
    // } else if ((pathname === '/') && showLegacyUI) {
    //   dispatch(startWizard(workspaces));
    // } else if ((pathname !== '/') || (urlReturn !== '/')) {
    //   dispatch(redirect('/pick-version'));
    // }
  };
}

export function validateSession () {
  return async (dispatch, getState) => {
    const location = selectLocation(getState());
    let isLoggedIn = selectIsLoggedIn(getState());

    dispatch({ type: VALIDATING_SESSION_START });
    if (!isLoggedIn && !includes(['/logout'], location.pathname)) {
      await dispatch(createSession()); // valido y creo la sesion usando la cookie del browser.
    }
    dispatch({ type: VALIDATING_SESSION_END });

    isLoggedIn = selectIsLoggedIn(getState());

    if (isLoggedIn && includes(['/login', '/'], location.pathname)) {
      dispatch(loginRedirect()); // si esta logueado lo redirecciono a donde corresponde
    } else if (!isLoggedIn && !includes(['/login'], location.pathname)) {
      dispatch(redirect('/login')); // si no esta logueado va al login
    }
    // por default queda donde está
  };
}

export function loginStart () {
  return (dispatch) => {
    dispatch({ type: LOGIN_START });
  };
}

export function login (user, password) {
  return async (dispatch, getState) => {
    try {
      const remember = selectRememberMe(getState());

      await api.auth.login({ email: user || '', password: password || '', remember });

      // reconstruye sesion
      await dispatch(createSession());

      // todo ok => redirecciona a donde corresponda
      dispatch(loginRedirect());

      // Force loading spinner of the Login button to change after the redirect
      dispatch({ type: LOGIN_FETCH_FINISHED });
    } catch (e) {
      dispatch(failureLoginCallback());
    }
  };
}

// TODO: Must comment line 196 in app.py of faraday
export function logOut () {
  return async (dispatch) => {
    try {
      const cookies = new Cookies();
      cookies.remove('remember_token');
      await api.auth.logout();
      dispatch({ type: LOGOUT_SUCCESS });
      dispatch(removeUnfinishedCommands());
      dispatch(showProcessingQueue(false));
    } catch (e) {
      dispatch({ type: LOGOUT_SUCCESS_REDIRECT });
    }
    dispatch(redirect('/login', false));
  };
}

export function hideErrorMessage () {
  return (dispatch) => {
    dispatch({ type: HIDE_ERROR_MESSAGE_LOGIN });
  };
}

export function setRememberMe (value) {
  return (dispatch) => {
    dispatch({ type: SET_REMEMBER_ME, value });
  };
}

export function forgotPassword (email) {
  return async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    try {
      await api.faraday.forgotPassword(email);
      return dispatch({ type: FORGOT_PASSWORD_SUCCESS, email });
    } catch (e) {
      return dispatch({ type: FORGOT_PASSWORD_FAILURE, data: { message: 'An error has occurred.' } });
    }
  };
}

export function resetPassword (hash, password, repeatPassword) {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    try {
      await api.faraday.resetPassword(hash, password, repeatPassword);
      return dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (e) {
      return dispatch({ type: RESET_PASSWORD_FAILURE, data: { message: 'An error has occurred.' } });
    }
  };
}

export function redirectToVersion (legacy, showTour) {
  return async (dispatch, getState) => {
    const workspaces = selectActiveWorkspaces(getState());
    const autoSelect = selectAutoSelectWs(getState());
    const workspaceActivity = selectWorkspaceActivity(getState());

    if (legacy) {
      await dispatch(updatePreferences({ legacyUI: legacy, tourFinished: showTour }));
      if (autoSelect && !isEmpty(workspaceActivity)) dispatch(validateAutoLoginWs(head(workspaceActivity), legacy));
      else dispatch(startWizard(workspaces));
    } else {
      await dispatch(updatePreferences({ legacyUI: legacy, sidebarTourFinished: showTour, dashboardTourFinished: showTour, assetVulnsTourFinished: showTour, vulnsTourFinished: showTour }));
      if (autoSelect && !isEmpty(workspaceActivity)) dispatch(validateAutoLoginWs(head(workspaceActivity), legacy));
      else dispatch(redirect('/workspaces'));
    }
  };
}
