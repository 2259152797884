import React from 'react';
import StandardTextField from 'Common/Components/StandardTextField';
import Wrapper from './styled';

const CustomCronExpression = ({
  id, value, setValue, onChangeCustomCronExpression, disabled
}) => {
  const isValid = (text) => {
    // eslint-disable-next-line prefer-regex-literals
    const re = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
    return re.test(text);
  };

  const onChange = (value) => {
    setValue(value);
    if (isValid(value)) {
      onChangeCustomCronExpression(value);
    } else {
      onChangeCustomCronExpression('');
    }
  };

  return (
    <Wrapper>
      <StandardTextField
        name="customCronExpression"
        value={ value }
        error={ value && !isValid(value) }
        errorMessage="Invalid cron expression"
        onChange={ onChange }
        disabled={ disabled }
        placeholder="0 0 1 */3 *"
        id={ id }
      />
    </Wrapper>
  );
};

export default CustomCronExpression;
