import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useIntl } from 'react-intl';
import { selectCustomAttributesBulkEdit } from 'store/CustomAttributes/selectors';
import head from 'lodash/head';

const CustomAttributes = ({ vulnsSelected, showBulkModal, showContextMenu, setCustomAttributeField }) => {
  const dispatch = useDispatch();
  const isBulk = vulnsSelected.length > 1;
  const customAttributes = useSelector(selectCustomAttributesBulkEdit);
  const hasCustomAttributes = customAttributes.length > 0;
  const intl = useIntl();
  const selectedVuln = head(vulnsSelected);

  const onClickCustomAttribute = (customAttribute) => {
    dispatch(setCustomAttributeField(customAttribute));
    dispatch(showBulkModal('custom_fields', isBulk ? '' : selectedVuln?.custom_fields[customAttribute.field_name]));
    dispatch(showContextMenu(false));
  };

  return (
    <ContextSubMenu
      enabled={ hasCustomAttributes }
      title={ intl.formatMessage({ id: 'manage.cm.edit.item.customAttributes' }) }
    >
      { hasCustomAttributes && customAttributes.map((ca) =>
        <MenuItem key={ ca.field_name } title={ ca.field_display_name } onClick={ () => onClickCustomAttribute(ca) } />
      )}
    </ContextSubMenu>
  );
};

export default CustomAttributes;
