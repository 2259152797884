import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  padding-right: 12px;
  margin-right: 12px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.darkBlueGrey};
`;
Title.displayName = 'Title';

export const Button = styled.div`
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: pointer;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  color: ${colors.dark2};
  height: 34px;
  width: 155px;
  border-radius: 2px;
  border: solid 1px ${colors.grey12};
  background-color: ${colors.iceBlue};
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${colors.dark2};
    }
  }
  &:hover {
    background-color: ${colors.white} ;
  }

  &:active {
    background-color: #d9e4ef;
  }
  
`;
