import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import CustomCronExpression from '../CustomCronExpression';
import {
  SetupSection, Subtitle, DescriptionSchedule, FieldWrapper, CustomRadioButton
} from './styled';

const CronExpression = ({
  onChangeCrontab, crontab, isCreateSchedule
}) => {
  const [customValue, setCustomValue] = useState(isCreateSchedule ? '' : crontab.expression);
  const isCustomCrontab = () => get(crontab, 'name', 'day') === 'custom';

  if (isEmpty(crontab)) return null;
  return (
    <SetupSection>
      <Subtitle>Set up the schedule</Subtitle>
      <DescriptionSchedule>Select when the Agent will run. If you need something specific, you can customize the recurrence.</DescriptionSchedule>
      <FieldWrapper>
        <CustomRadioButton id="scheduler-modal-radio-day" label="Every day at 4 A.M." name="radio" value="0 4 * * *" onChange={ (e) => onChangeCrontab({ name: 'day', expression: e.target.value }) } checked={ crontab.name === 'day' } />
        <CustomRadioButton id="scheduler-modal-radio-week" label="Every week (Sundays at 4 A.M.)" name="radio" value="0 4 * * 0" onChange={ (e) => onChangeCrontab({ name: 'week', expression: e.target.value }) } checked={ crontab.name === 'week' } />
        <CustomRadioButton id="scheduler-modal-radio-month" label="Every month (On 1st at 4 A.M.)" name="radio" value="0 4 1 * *" onChange={ (e) => onChangeCrontab({ name: 'month', expression: e.target.value }) } checked={ crontab.name === 'month' } />
        <CustomRadioButton id="scheduler-modal-radio-custom" label="Custom Recurrence:" name="radio" value={ customValue } onChange={ (e) => onChangeCrontab({ name: 'custom', expression: e.target.value }) } checked={ isCustomCrontab() } />
        <CustomCronExpression id="scheduler-modal-input-custom" value={ isCustomCrontab() ? customValue : '' } setValue={ setCustomValue } onCancel={ () => onChangeCrontab({ name: 'day', expression: '0 4 * * *' }) } onChangeCustomCronExpression={ (value) => onChangeCrontab({ name: 'custom', expression: value }) } disabled={ !isCustomCrontab() } />
      </FieldWrapper>
    </SetupSection>
  );
};

export default CronExpression;
