import BlueButton from 'Common/Components/BlueButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { newJob } from 'store/Jobs/actions';
import { Wrapper, Title, EmptyImage } from './styled';

const NoSelection = () => {
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <EmptyImage />
      <Title>You don’t have any Jobs yet. </Title>
      <BlueButton label="Create a Job" onClick={ () => dispatch(newJob()) } />
    </Wrapper>
  );
};
export default NoSelection;
