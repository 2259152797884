import api from 'services/api';
import get from 'lodash/get';
import { updateVuln } from 'store/Manage/actions';
import { SEARCH_VULNS_FAIL } from 'store/Manage/types';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import { removeColumn, renameColumn } from 'store/Preferences/manage/actions';
import * as types from './types';
import * as errors from './errors';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import { updateVulnGeneral } from 'store/ManageGeneral/actions';

export function getCustomAttributes () {
  return async (dispatch) => {
    try {
      const response = await api.customAttributes.getCustomAttributes();
      const orderedFields = response.sort((a, b) => a.field_order - b.field_order);
      return dispatch({ type: types.GET_CUSTOM_ATTRIBUTES, attributes: orderedFields });
    } catch (e) {
      dispatch({ type: SEARCH_VULNS_FAIL, data: errors.GET_CUSTOM_ATTRIBUTES_FAILED });
      return dispatch({ type: types.GET_CUSTOM_ATTRIBUTES, attributes: [] });
    }
  };
}

export function addCustomAttribute (ca) {
  return async (dispatch) => {
    try {
      const createdCa = await api.customAttributes.addCustomAttribute(ca);
      return dispatch({ type: types.ADD_CUSTOM_ATTRIBUTE, ca: createdCa });
    } catch (e) {
      return dispatch({ type: SEARCH_VULNS_FAIL, data: errors.ADD_CUSTOM_ATTRIBUTES_FAILED });
    }
  };
}

export function setCustomAttributeToEdit (ca) {
  return (dispatch) => {
    dispatch({ type: types.SET_CUSTOM_ATTRIBUTE_TO_EDIT, ca });
  };
}

export function removeCustomAttribute (ca) {
  return async (dispatch) => {
    try {
      const idToDelete = get(ca, 'id', null);
      const columnToDelete = get(ca, 'field_display_name', null);
      if (idToDelete && columnToDelete) {
        await api.customAttributes.removeCustomAttribute(ca);
        dispatch({ type: types.DELETE_CUSTOM_ATTRIBUTE, idToDelete });
        dispatch(removeColumn(columnToDelete));
      }
    } catch (e) {
      dispatch({ type: SEARCH_VULNS_FAIL, data: errors.REMOVE_CUSTOM_ATTRIBUTE_FAILED });
    }
  };
}

export function renameCustomAttributeColumn (caToEdit) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentAttributes = selectCustomAttributes(state);
    const actualFieldName = get(currentAttributes.find((f) => f.id === caToEdit.id), 'field_display_name', '');
    const newFieldName = get(caToEdit, 'field_display_name', '');
    const newColumn = {
      actualFieldName,
      newFieldName
    };
    dispatch(renameColumn(newColumn));
  };
}

export function editCustomAttribute (caToUpdate) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentCa = selectCustomAttributes(state);

    try {
      const updatedCa = await api.customAttributes.updateCustomAttribute(caToUpdate);
      const newCaList = currentCa.map((ca) => {
        if (ca.id === updatedCa.id) return updatedCa;
        return ca;
      });
      dispatch(renameCustomAttributeColumn(caToUpdate));
      return dispatch({ type: types.EDIT_CUSTOM_ATTRIBUTE, newCaList });
    } catch (e) {
      return dispatch({ type: SEARCH_VULNS_FAIL, data: errors.EDIT_CUSTOM_ATTRIBUTES_FAILED });
    }
  };
}

export function setVulnCustomAttribute (vuln, fieldName, fieldValue, entity) {
  return (dispatch) => {
    const { custom_fields } = vuln;
    const value = fieldValue === '(Empty)' ? '' : fieldValue;
    const newCustomAttribute = { ...custom_fields, [fieldName]: value };
    if (entity === 'vulnsAssets') dispatch(updateVulnFromAsset(vuln, 'custom_fields', newCustomAttribute));
    else if (entity === 'vulnsGeneral') dispatch(updateVulnGeneral(vuln, 'custom_fields', newCustomAttribute));
    else dispatch(updateVuln(vuln, 'custom_fields', newCustomAttribute));
  };
}

function setCustomAttributes (attributes) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_CUSTOM_ATTRIBUTES, attributes });
      const promises = attributes.map((ca) => api.customAttributes.updateCustomAttribute(ca));
      return await Promise.all(promises);
    } catch (e) {
      return dispatch({ type: SEARCH_VULNS_FAIL, data: errors.EDIT_CUSTOM_ATTRIBUTES_FAILED });
    }
  };
}

export function dragCustomAttribute (dragResult) {
  return (dispatch, getState) => {
    const state = getState();
    const customAttributes = selectCustomAttributes(state);

    const destination = get(dragResult, 'destination', null);
    const source = get(dragResult, 'source', null);
    const destDroppableId = get(destination, 'droppableId', null);
    const sourceDroppableId = get(source, 'droppableId', null);
    const sourceIndex = get(source, 'index', null);
    const destIndex = get(destination, 'index', null);

    if (!destination) return;
    if (destDroppableId === sourceDroppableId && destIndex === sourceIndex) return;

    const fieldToRelocate = customAttributes[sourceIndex];
    const newAttributes = [...customAttributes];
    // Starting from the index of the action dragged, remove one element
    newAttributes.splice(sourceIndex, 1);
    // Add the action to relocate on the destination index
    newAttributes.splice(destIndex, 0, fieldToRelocate);
    // update the local order index of all fields starting from 1
    const reorderedAttributes = newAttributes.map((f, i) => ({ ...f, field_order: i + 1 }));
    // get only the elements whose order index was changed
    dispatch(setCustomAttributes(reorderedAttributes));
  };
}
