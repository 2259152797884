import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Wrapper } from './styled';

const DateInput = ({ onValueSelect }) => {
  const [startDate, setStartDate] = useState(null);

  const handleDateChange = (date) => {
    setStartDate(date);
    onValueSelect(moment(date).format('YYYY-MM-DD')); // Formato a gusto
  };

  return (
    <Wrapper>
      <ReactDatePicker
        selected={ startDate }
        onChange={ handleDateChange }
        inline
        withPortal
        shouldCloseOnSelect={ false }
      />
    </Wrapper>
  );
};

export default DateInput;
