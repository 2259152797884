import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_SCHEDULER_NO_WS_WARNING } from 'store/modals/modals';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';
import { redirect } from 'store/Router/actions';
import Redirect from './styled';

const NoWsWarning = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'schedule.noWsModal.title' });
  const done = intl.formatMessage({ id: 'schedule.noWsModal.button' });

  const onClick = () => {
    dispatch(redirect('/automation/agents'));
  };

  const onDone = () => {
    dispatch(closeModal(MODAL_SCHEDULER_NO_WS_WARNING));
  };

  return (
    <ConfirmNavigationModal
      title={ title }
      message={ intl.formatMessage({ id: 'schedule.noWsModal.message' },
        { agents: <Redirect onClick={ onClick }>Agents</Redirect> }) }
      onDone={ onDone }
      done={ done }
    />
  );
};

export default NoWsWarning;
