import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToHostDetail, setHostForWorking } from 'store/Contextualization/AssetDetail/actions';
import { setPageNumber } from 'store/Filters/actions';
import { selectAssetRow, unSelectAll } from 'store/Host/actions';
import { IpWrapper, Ip, GoToButton, GoToIcon } from '../styled';
import { redirect } from 'store/Router/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

export const IpColumn = ({ host, children, showIcon }) => { // eslint-disable-line import/prefer-default-export
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const accessHost = (e) => {
    dispatch(setHostForWorking(host));
    dispatch(redirect(`/host/${currentWorkspace}/${host.id}/services`));
    dispatch(selectAssetRow(e, host));
  };

  const handleClick = (e) => {
    dispatch(setPageNumber('vulnsAssets', 1));
    dispatch(unSelectAll());
    dispatch(redirectToHostDetail(host.id));
    dispatch(selectAssetRow(e, host));
  };
  return (
    <IpWrapper>
      <Ip onClick={ handleClick }>{children}</Ip>
     { showIcon &&
      <GoToButton onClick={ accessHost } title="Explore Asset">
        <GoToIcon />
      </GoToButton>
      }
    </IpWrapper>
  );
};
