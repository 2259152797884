import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectVulnDetail } from 'store/ManageGeneral/selectors';
import ExpandableWrapper from '../ExpandableWrapper';
import OwnerWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Owner/styled';
import useExpandable from 'Hooks/useExpandable';

const TAB = 'general';
const ID = 'owner';

const Owner = () => {
  const intl = useIntl();
  const currentVuln = useSelector(selectVulnDetail);
  const owner = get(currentVuln, 'owner', '');
  const isExpanded = useExpandable(TAB, ID, owner);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.owner' }) } tab={ TAB } id={ ID }>
      <OwnerWrapper children={ owner } />
    </ExpandableWrapper>
  );
};

export default Owner;
