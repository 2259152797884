import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { redirect } from 'store/Router/actions';
import { useDispatch } from 'react-redux';
import { MessageWrapper, StyledLink } from './styled';

const DeleteMessage = ({ pipelinesCount }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const message = `${pipelinesCount} ${intl.formatMessage({ id: 'jobs.delete.message.pipelinesCount' })}`;

  if (pipelinesCount === 0) return <MessageWrapper children={ intl.formatMessage({ id: 'jobs.delete.message' }) } />;
  return (
    <MessageWrapper>
      <FormattedMessage
        id="jobs.delete.message.withPipelines"
        values={ { pipelinesCount: <StyledLink children={ message } onClick={ () => dispatch(redirect('/automation/pipelines')) } /> } }
      />
    </MessageWrapper>
  );
};

export default DeleteMessage;
