import styled from 'styled-components';

const NewAttributeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: auto;
    margin-bottom: 10px;
    margin-right: auto;
`;

NewAttributeWrapper.displayName = 'NewAttributeWrapper';

export default NewAttributeWrapper;
