import styled from 'styled-components';

const Text = styled.span`
  text-transform: ${(props) => (props.capitalize ? props.capitalize : 'none')};
  font-size: 12.5px;
  color: #53686f;
  font-weight: 400;
`;
Text.displayName = 'Text';

export default Text;
