import filter from 'lodash/filter';
import get from 'lodash/get';
import size from 'lodash/size';
import defaultFields from 'store/Services/fields';
import { updatePreferences } from 'store/Preferences/actions';
import { selectServicesFields } from './selectors';

export function setFieldVisibility (field, value) {
  return async (dispatch, getState) => {
    let services_table = selectServicesFields(getState());

    if (!services_table) services_table = { ...defaultFields };
    if (!services_table[field].locked) {
      services_table[field].visible = value;

      if (value) services_table[field].order = Object.keys(services_table).filter((key) => services_table[key].visible).length;
      else {
        let cont = 1;
        Object.keys(services_table).filter((f) => services_table[f].visible).sort((a, b) => (services_table[a].order < services_table[b].order)).forEach((f2) => {
          services_table[f2].order = cont;
          cont += 1;
        });
      }

      dispatch(updatePreferences({ services_table }));
    }
  };
}

export function setNewOrderColumns (columns) {
  return async (dispatch, getState) => {
    let services_table = selectServicesFields(getState());
    if (!services_table) services_table = { ...defaultFields };

    const fixedColumns = size(filter(services_table, { locked: true }));

    columns.forEach((column) => {
      if (column) {
        if (!get(services_table, `${column}.locked`, false)) {
          services_table[column].order = columns.indexOf(column) + 1 + fixedColumns;
        }
      }
    });

    dispatch(updatePreferences({ services_table }));
  };
}

export function resetDefault () {
  return async (dispatch) => {
    dispatch(updatePreferences({ services_table: defaultFields }));
  };
}
