import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select, { components } from 'react-select';
import { groupSelectContext } from 'Common/styles/style';
import { VULNS_GROUP_BY_KEYS } from 'store/Filters/constants';
import { FormattedMessage } from 'react-intl';

class GroupFieldSelector extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const {
      groupBy,
      setGroupBy,
      clearGroupBy
    } = this.props;

    const alias = 'group';

    const SingleValue = ({ children, ...props }) => (
      components.SingleValue && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.SingleValue { ...props }>
        {`Group By: ${children}`}
      </components.SingleValue>
      )
    );

    const options = VULNS_GROUP_BY_KEYS;

    return (
      <div className="mr-26">
        <Select
          styles={ groupSelectContext }
          isSearchable={ false }
          disabled={ false }
          value={ options.filter((opt) => opt.value === groupBy) }
          components={ { SingleValue } }
          placeholder={ <FormattedMessage id={ `${alias}.placeholder` } /> }
          onChange={ (e) => {
            if (e.value.length > 0) {
              setGroupBy(e.value);
            } else {
              clearGroupBy();
            }
          } }
          options={ options.filter((opt) => opt.value !== groupBy) }
        />
      </div>
    );
  }
}

export default withRouter(GroupFieldSelector);
