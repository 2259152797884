import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';
import {
  Wrapper as WrapperCustomList, DataItem, DataContainer, Text
} from 'Common/Components/CustomList/styled';
import StandardTextField from 'Common/Components/StandardTextField';
import StandardTextAreaField from 'Common/Components/StandardTextAreaField';
import { ReactComponent as _ModalIcon } from 'Images/icon_modal_asset.svg';

export const Title = styled.div`
  font-size: 21.5px;
  font-stretch: normal;
  color: ${colors.blueCerulean};
  display: inline;
  width: fit-content;
  font-weight: 500;
`;
Title.displayName = 'Title';

export const TitleContainer = styled.div`
  display: flex;
`;
TitleContainer.displayName = 'TitleContainer';

export const ModalIcon = styled(_ModalIcon)`
  margin-left: 20px;
`;

export const Wrapper = styled.div`

  border-radius: 8px 8px 2px 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  border-top: 10px solid #419bf9;
  padding: 40px 30px;
  ${slideIn}
`;
Wrapper.displayName = 'Wrapper';

export const ModalBody = styled.div`
  margin-top: 24px;
  display: flex;
`;
ModalBody.displayName = 'ModalBody';

export const ButtonsWrapper = styled.div`
  margin-left: auto;
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const LeftSide = styled.div`
  width: 360px;
  display: inline-block;
  margin-right: 24px;
  padding-top: 8px;
  text-align: initial;

  textarea {
    resize: none;
  }

  & >:nth-child(2) {
    margin-top: 13px;
  }
`;
LeftSide.displayName = 'LeftSide';

export const RightSide = styled.div`
  width: 337px;
  display: inline-block;
  padding-top: 8px;
  text-align: initial;

  ${WrapperCustomList} {
    .input-list-title {
      font-weight: 400;
      color: ${colors.grey17};
      margin-bottom: 8px;
    }
    input {
      width: 287px;
    }
  }

  ${DataItem} {
    width: 287px !important;
    margin-top: 5px;

    ${Text} {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span {
      float: right;
    }
  }

  ${DataContainer} {
    max-height: 227px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 287px;
    margin-top: 5px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 6px;
    }
  }
`;
RightSide.displayName = 'RightSide';

const Button = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  font-weight: 400;
`;

export const CancelButton = styled(Button)`
  border: solid 1px ${colors.dark6};
  color: ${colors.dark2};
  background-color: white;
  margin-right: 8px;
`;
CancelButton.displayName = 'CancelButton';

export const CreateButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? colors.grey20 : colors.blueCerulean)};
  color: white;
  border: 0;
`;
CreateButton.displayName = 'CreateButton';

export const ErrorMessage = styled.p`
  font-size: 14.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.redPink};
  float: left;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const WrapperField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 21px;
`;
WrapperField.displayName = 'WrapperField';

export const TextWrapper = styled.div`
  display: flex;
  .ip {
    margin-right: 21px;
  }
`;
TextWrapper.displayName = 'TextWrapper';

export const TextField = styled(StandardTextField)`
  color: ${colors.grey17};
  font-weight: 400;
  font-size: 12.5px;
  margin-bottom: 0;
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  
  span {
    font-weight: 400;
  }
  input {
    box-shadow: ${(props) => (props.error ? 'inset 0 -1px 0 0 #ea3158' : 'inset 0 -1px 0 0 #afafaf')};
  }
  svg {
    top: 34px;
  }
`;
TextField.displayName = 'TextField';

export const Description = styled(StandardTextAreaField)`
  margin-top: 23px;
  span {
    color: ${colors.dark2};
    font-weight: 600;
    font-size: 13.5px;
  }
  textarea {
    height: 130px;
    margin-top: 3px;
    padding-top: 13px;
    background-color: ${(props) => (props.isEmpty ? colors.paleGrey : colors.white)};
    box-shadow: inset 0 -1px 0 0 #afafaf;
  }
`;
Description.displayName = 'Description';

export const Label = styled.div`
  margin-left: 8px;
  font-size: 13.5px;
`;
Label.displayName = 'Label';

export const HostnameTitle = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.dark2};
  margin-bottom: 16px;
`;
HostnameTitle.displayName = 'HostnameTitle';

export const Workspaces = styled.div`
  margin-top: 15px;
  width: 337px;
`;
Workspaces.displayName = 'Workspaces';
