import { css } from 'styled-components';

export const verticalLine = css`
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    left: 0px;
    background: #979797;
    margin-left: 17px;
    margin-right: 17px;
  }
`;

export const bigDot = (color) => css`
  &:after {
    width: 34px;
    content: ' ';
    background: ${color};
    height: 34px;
    border-radius: 19px;
    position: absolute;
    left: 0;
  }
`;

export const smallDot = (color) => css`
  &:after {
    width: 13px;
    content: ' ';
    background: ${color};
    height: 13px;
    border-radius: 19px;
    position: absolute;
    margin-right: 28px;
    right: 100%;
    top: 0;
  }
`;

export const smallDotEnd = (color) => css`
  ${smallDot(color)}
  top: 4px;
`;
