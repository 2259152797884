import api from 'services/api';
import * as types from './types';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import {
  selectHostsList, selectLastSelected, selectSelectAllServices,
  selectServiceDetailId, selectServices, selectServicesCount, selectServicesSelected,
  selectShowDetail
} from './selectors';
import { selectAdvancedFilterQueryParam, selectQueryParam, selectRowsPerPage } from 'store/Filters/selectors';
import { setFilterError, setPageNumber } from 'store/Filters/actions';
import { closeModal } from 'store/modals/actions';
import { MODAL_CREATE_SERVICE } from 'store/modals/modals';
import get from 'lodash/get';

export function somethingWentWrong (errorMessage) {
  return async (dispatch) => {
    dispatch({ type: types.SERVICES_FAIL, errorMessage: errorMessage || 'There was an error, please try again.' });
  };
}

export function clearError () {
  return async (dispatch) => {
    dispatch({ type: types.SERVICES_CLEAR_ERROR });
  };
}

export function fetchingStart () {
  return async (dispatch) => {
    dispatch({ type: types.SERVICES_FETCHING });
  };
}

const setServicesFilterError = () => async (dispatch) => dispatch({ type: types.SET_SERVICES_FILTER_ERROR });

export function getServices () {
  return async (dispatch, getState) => {
    dispatch(fetchingStart());
    const workspace = selectCurrentWorkspace(getState());
    let advancedFilterQueryParam = [];

    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'services');
    } catch (e) {
      dispatch(setFilterError('services', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setServicesFilterError());
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('services', getState());
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const response = await api.service.getServices(workspace, queryParam);
      const data = response.services.map((service) => service.value);
      return dispatch({ type: types.SERVICES_GET_SUCCESS, data, count: response.count });
    } catch (error) {
      const message = get(error, 'message', 'There was an error, please try again.');
      return dispatch(somethingWentWrong(message));
    }
  };
}

export function redirectToService () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/service/${currentWorkspace}`));
  };
}

export function redirectToServiceDetail (id) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/service/${currentWorkspace}/${id}`));
  };
}

export function showServiceDetail (service) {
  return (dispatch) => {
    dispatch({ type: types.SERVICES_SHOW_DETAIL, service });
  };
}

export function loadServiceDetail (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const service = await api.service.fetchById(currentWorkspace, id);
        dispatch(showServiceDetail(service));
      } catch (e) {
        dispatch(redirectToService());
      }
    }
  };
}

export function hideServiceDetail () {
  return (dispatch, getState) => {
    const showServiceDetail = selectShowDetail(getState());
    if (showServiceDetail) dispatch({ type: types.SERVICES_HIDE_DETAIL });
  };
}

export const unselectedRowFromSelectAll = (value) => (dispatch) => {
  dispatch({ type: types.SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL, value });
};

export const selectTotalServices = () => (dispatch) => {
  return dispatch({ type: types.SELECT_TOTAL_SERVICES });
};

export const unselectTotalServices = () => (dispatch) => {
  return dispatch({ type: types.UNSELECT_TOTAL_SERVICES });
};

export const autoSelectService = (service) => (dispatch) => {
  dispatch({ type: types.SERVICES_AUTOSELECT, service });
};

const addDeleteController = (services, serviceList) => {
  const allServicesAreSelected = services.every((testService) => serviceList.some((service) => service._id === testService._id));

  if (allServicesAreSelected) return [types.SERVICE_UNSELECTED, services];
  return [types.SERVICE_SELECTED, services];
};

const selectCalculator = (e, service, serviceList, areServiceSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = serviceList.findIndex((el) => el._id === service._id);
  const servicesSelected = selectServicesSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areServiceSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const services = serviceList.slice(start, end);
    const [type, payload] = addDeleteController(services, servicesSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([service], servicesSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, service) => (dispatch, getState) => {
  const allService = selectServices(getState());
  const serviceSelected = selectServicesSelected(getState());
  const selectAll = selectSelectAllServices(getState());
  dispatch(selectCalculator(e, service, allService, serviceSelected.length > 0));
  if (selectAll) {
    dispatch(unselectedRowFromSelectAll(true));
    dispatch(unselectTotalServices());
  }
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_SERVICE });

export const selectAllService = () => (dispatch, getState) => {
  const servicesList = selectServices(getState());
  const servicesSelected = selectServicesSelected(getState());
  const serviceCount = selectServicesCount(getState());
  const pageSize = selectRowsPerPage('services', getState());

  if (servicesSelected.length === serviceCount || servicesSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_SERVICE, servicesList });
};

export function showServiceModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.SERVICES_SHOW_MODAL_DELETE });
  };
}

export function hideServiceModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.SERVICES_HIDE_MODAL_DELETE });
  };
}

export function deleteServicesSelected () {
  return async (dispatch, getState) => {
    dispatch({ type: types.SERVICES_DELETE_START });
    const state = getState();
    const currentWorkspace = selectCurrentWorkspace(state);
    const servicesSelected = selectServicesSelected(state);
    const servicesSelectedID = servicesSelected.map(service => service._id);
    const selectAll = selectSelectAllServices(state);

    const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'services');
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('services', getState());
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;
    try {
      if (selectAll) {
        await api.service.deleteAllServices(currentWorkspace, { filters: queryParam.filters });
      } else {
        const response = await api.service.deleteService(currentWorkspace, servicesSelectedID);
        dispatch({ type: types.SERVICES_DELETE_SUCCESS, response });
      }
      dispatch(hideServiceModalDelete());
      return dispatch(getServices());
    } catch (err) {
      dispatch({ type: types.SERVICES_DELETE_FAIL });
    }
  };
}

export function updateService (serviceId, valueObj) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const serviceList = selectServices(getState());
    const serviceCount = selectServicesCount(getState());
    const serviceDetailId = selectServiceDetailId(getState());
    const newService = await api.service.updateService(workspaceSelected, { id: serviceId, ...valueObj });
    const index = serviceList.findIndex((x) => serviceId === x.id || serviceId === x._id);
    serviceList[index] = { ...newService, severity_counts: serviceList[index].severity_counts };
    dispatch({ type: types.SERVICES_UPDATE_SUCCESS, data: serviceList, serviceCount });
    if (serviceDetailId && newService._id === serviceDetailId) dispatch(showServiceDetail(newService));
  };
}

export function resetServiceDetail () {
  return async (dispatch) => dispatch({ type: types.SERVICES_RESET_EDIT });
}

export function setPageNumberServices (pageNumber) {
  return (dispatch) => {
    dispatch(setPageNumber('services', pageNumber));
    dispatch(getServices());
  };
}

export function resetState () {
  return (dispatch) => {
    dispatch({ type: types.SERVICES_RESET_STATE });
  };
}

export function createService (service) {
  return async (dispatch, getState) => {
    try {
      const { name, assets, status, ports, protocol, version, description, owned } = service;
      const { workspaceSelected } = getState().faraday;
      const hosts = selectHostsList(getState());
      const hostSelected = hosts.find((h) => h.ip === assets[0]);
      const hostSelectedId = get(hostSelected, '_id', 0);
      const portsParsed = ports.toString().split(',');

      const response = await api.hostDetail.addService(workspaceSelected, hostSelectedId, name, status, portsParsed, protocol, version, description, owned);

      dispatch({ type: types.SERVICES_ADD_SERVICE_SUCCESS, services: response });

      dispatch(closeModal(MODAL_CREATE_SERVICE));
    } catch (e) {
      const message = get(e, 'message', 'There was an error, please try again.');
      return dispatch(somethingWentWrong(message));
    }
  };
}

export const setCreateServiceError = (error, errorMessage, errorTitle) => (dispatch) => dispatch({ type: types.SET_ERROR_CREATE_SERVICE, error, errorMessage, errorTitle });
