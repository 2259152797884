import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 11px;
  display: flex;
  flex-direction: row;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
