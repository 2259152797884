import fetchApi from 'services/api/connector';

export const fetchPipelines = () => fetchApi('pipelines', { method: 'GET' });
export const createPipeline = (data) => fetchApi('pipelines', { method: 'POST', data });
export const editPipeline = (id, data) => fetchApi(`pipelines/${id}`, { method: 'PATCH', data });
export const removePipeline = (id) => fetchApi(`pipelines/${id}`, { method: 'DELETE' });
export const runPipeline = (id) => fetchApi(`pipelines/${id}/run`, { method: 'POST' });
export const enable = (id) => fetchApi(`pipelines/${id}/enable`, { method: 'POST' });
export const disable = (id) => fetchApi(`pipelines/${id}/disable`, { method: 'POST' });
export const getPipeline = (id) => fetchApi(`pipelines/${id}`, { method: 'GET' });
export const clonePipeline = (id) => fetchApi(`pipelines/${id}/clone`, { method: 'POST' });
