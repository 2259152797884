import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequestUrl } from 'services/api/connector';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import copyToClipboard from 'utils/clipboard';
import CopyApiLinkIcon from './styled';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';

const CopyApiLink = ({ hasServices, servicesSelected, entity }) => {
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyApiLink' });
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'service' });
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const serviceId = get(servicesSelected, '[0]._id');

  const copyApiLink = () => {
    if ((entity === 'services') || (entity === 'assetServices')) {
      if (serviceId && workspaceSelected) {
        const servicePath = `ws/${workspaceSelected}/services/${serviceId}`;
        const link = getRequestUrl(servicePath);
        copyToClipboard(link);
      }
    } else {
      const servicePath = `services/${serviceId}`;
      const link = getRequestUrl(servicePath);
      copyToClipboard(link);
    }
  };

  const handleClick = () => {
    if (hasServices) {
      if (servicesSelected.length === 1) copyApiLink();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <CopyApiLinkIcon /> } title={ title } onClick={ handleClick } disabled={ !hasServices } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default CopyApiLink;
