import React from 'react';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import Warning from '../Warning';
import { Wrapper, Label } from './styled';
import { selectJobTarget, selectActions } from 'store/Jobs/selectors';

const ActionSelector = ({ value, onChange }) => {
  const target = useSelector(selectJobTarget);
  const options = useSelector(() => selectActions(target));

  return (
    <Wrapper>
      <Label>Action</Label>
      <StandardDropdown
        width="200px"
        field="action"
        options={ options }
        updateValue={ (f, v) => onChange(v) }
        defaultValue={ value }
        selectObject
      />
      { value === 'delete' && <Warning /> }
    </Wrapper>
  );
};

ActionSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ActionSelector;
