import React from 'react';
import PropTypes from 'prop-types';
import CreateButton from './components/CreateButton';
import DeleteButton from './components/DeleteButton';
import EditButton from './components/EditButton';
import { Wrapper, ButtonWrapper, Title } from './styled';
import { FormattedMessage } from 'react-intl';

const ActionBar = ({ toggleModalCreateSchedule, toggleModalEditSchedule }) => (
  <Wrapper>
    <Title children={ <FormattedMessage id='schedule.title' /> } />
    <ButtonWrapper>
      <CreateButton toggleSideBar={ toggleModalCreateSchedule } />
      <EditButton toggleSideBar={ toggleModalEditSchedule } />
      <DeleteButton />
    </ButtonWrapper>
  </Wrapper>
);

ActionBar.propTypes = {
  toggleModalCreateSchedule: PropTypes.func.isRequired,
  toggleModalEditSchedule: PropTypes.func.isRequired
};

export default ActionBar;
