import api from 'services/api';
import { selectPreferences } from 'store/Preferences/selectors';

export const SAVE_PREFERENCES_SUCCESS = 'SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_FAIL = 'SAVE_PREFERENCES_FAIL';

// Update Preferences
export function updatePreferences (items) {
  return async (dispatch, getState) => {
    const itemsCopy = JSON.parse(JSON.stringify(items));
    const preferences = selectPreferences(getState());
    const newPreferences = { ...preferences, ...itemsCopy };

    try {
      await api.preferences.save({ preferences: newPreferences });
      return dispatch({ type: SAVE_PREFERENCES_SUCCESS, preferences: newPreferences });
    } catch (e) {
      return dispatch({ type: SAVE_PREFERENCES_FAIL, data: e.message });
    }
  };
}
