import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBulkUpdateValue } from 'store/Manage/actions';
import { selectBulkUpdateCustomAttribute, selectModalBulkUpdateValue } from 'store/Manage/selectors';
import get from 'lodash/get';
import DateInput from 'Common/Components/Contextualization/SearchBar/components/BasicFilter/components/DateInput';
import { DateWrapper, DateBox, InputWrapper, DateFieldName } from '../../styled';

const Date = () => {
  const [showDateInput, setShowDateInput] = useState(false);
  const dispatch = useDispatch();
  const customAttribute = useSelector(selectBulkUpdateCustomAttribute);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const onChange = (value) => dispatch(setBulkUpdateValue(value));
  const fieldName = get(customAttribute, 'field_display_name', '');

  const selectDate = (val) => {
    setShowDateInput(false);
    onChange(val);
  };

  return (
    <DateWrapper>
      <DateFieldName>{ fieldName }</DateFieldName>
      <DateBox isEmpty={ !bulkUpdateValue } onClick={ () => setShowDateInput(true) }>
      { (!bulkUpdateValue && !showDateInput) && 'This field is empty. Click to edit.'}
      { (bulkUpdateValue && !showDateInput) && bulkUpdateValue }
      </DateBox>
      { showDateInput && <InputWrapper><DateInput onValueSelect={ (val) => selectDate(val) } /></InputWrapper> }
    </DateWrapper>
  );
};

export default Date;
