import React from 'react';
import { PropTypes } from 'prop-types';
import { selectVulnDetailSelectedTab } from 'store/ManageGeneral/selectors';
import { useSelector } from 'react-redux';
import TabWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/styled';

const Tabs = ({ onScroll, tabRef }) => {
  const selectedTab = useSelector(selectVulnDetailSelectedTab);

  return (
    <TabWrapper ref={ tabRef } onScroll={ onScroll }>
      { selectedTab.component }
    </TabWrapper>
  );
};

Tabs.propTypes = {
  onScroll: PropTypes.func.isRequired,
  tabRef: PropTypes.object.isRequired
};

export default Tabs;
