import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getModelFields } from 'store/Filters/actions';
import isEmpty from 'lodash/isEmpty';
import Wrapper from './styled';
import Header from './components/Header';
import Attributes from './components/Attributes';
import NewAttribute from './components/NewAttribute';

const JobRule = ({ rule, highlight }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getModelFields());
  }, [dispatch]);

  return (
    <Wrapper highlight={ highlight && isEmpty(rule.attributes) }>
      <Header rule={ rule } />
      <Attributes rule={ rule } />
      <NewAttribute rule={ rule } />
    </Wrapper>
  );
};

JobRule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired,
  highlight: PropTypes.bool
};

JobRule.defaultProps = {
  highlight: false
};

export default JobRule;
