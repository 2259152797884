import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Loupe } from 'Images/icon_loupe.svg';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding-right: 11px;
  margin-right: 12px;
`;
Wrapper.displayName = 'Wrapper';

export const Input = styled.input`
  border-radius: 4px;
  box-shadow: 0 0 10px 10px rgba(232, 232, 232, 0.16);
  border: solid 1px #e8e8e8;
  background-color: ${colors.white};
  width: 100%;
  height: 36px;
  padding: 9px 11px 9px 11px;
  font-size: 14px;
  ::placeholder {
    font-size: 14px;
    color: #646464;
    opacity: 0.45;
  }
`;
Input.displayName = 'Input';

export const Button = styled.button`
  position: absolute; 
  top: 4px;
  right: 20px;
  background-color: #FFF;
  border: none;
`;
Button.displayName = 'Button';

export const SearchIcon = styled(Loupe)`
  g, path {
    fill: #a1b4b6;
  }
`;
SearchIcon.displayName = 'SearchIcon';
