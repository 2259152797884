import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useResizeY } from 'Hooks/useResize/index';
import { FiltersContainer, Content } from './styled';
import SavedFilters from './components/SavedFilters';
import QuickFilters from './components/QuickFilters';
import Header from './components/Header';
import { Resizer } from './components/styled';

const Filters = ({ entity }) => {
  const ref = useRef(null);
  const childRef = useRef(null);

  const minHeight = (min) => Math.max(min - 97, 0);
  const defaultHeight = (def) => Math.max(def - 97, 0);
  const resizerHeight = useResizeY(childRef, ref, defaultHeight(350), minHeight(125));

  return (
    <FiltersContainer>
      <Header entity={ entity } />
      <Content>
        { (entity === 'vulns' || entity === 'assets') &&
          <>
            <SavedFilters reference={ childRef } containerHeight={ resizerHeight } entity={ entity } />
            <Resizer ref={ ref } />
          </>
        }
        <QuickFilters />
      </Content>
    </FiltersContainer>
  );
};

Filters.propTypes = {
  entity: PropTypes.string.isRequired
};

export default Filters;
