import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  z-index: 2;
`;
Wrapper.displayName = 'Wrapper';

export const StyledInput = styled.input`
  border: 0px;
  background-color: transparent;
  width: 165px;
  text-align: left;
  font-size: 11.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  white-space: nowrap;
`;
StyledInput.displayName = 'StyledInput';

export const TagContainer = styled.div`
  cursor: default;
  display:flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 6px 7px 5.5px 7px;
  margin-right: 5px;
  background-color: ${colors.paleGrey};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  border-top: solid 1px ${colors.grey12};
  border-bottom: solid 1px ${colors.grey12};
  border: solid 1px ${colors.grey12};
  border-radius: 0px 2px 2px 0px;
`;

TagContainer.displayName = 'TagContainer';
