import React from 'react';
import { PropTypes } from 'prop-types';
import ActionButton from './ActionButton';
import {
  Wrapper, EditIcon, DeleteIcon
} from './styled';

const ActionBar = ({
  showActionBar, setShowJobDetail, setShowActionBar, handleDelete
}) => (
  <>
    { showActionBar
      ? (
      <Wrapper>
        <ActionButton onClick={ () => { setShowJobDetail(true); setShowActionBar(false); } } title="Details" icon={ <EditIcon /> } />
        <ActionButton onClick={ handleDelete } title="Delete" icon={ <DeleteIcon /> } />
      </Wrapper>
        )
      : null}
  </>
);

ActionBar.propTypes = {
  showActionBar: PropTypes.bool.isRequired,
  setShowJobDetail: PropTypes.func.isRequired,
  setShowActionBar: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default ActionBar;
