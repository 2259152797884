import defaultFields from 'store/Host/contextFields';

const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  hostsList: [],
  fields: defaultFields,
  hostCount: 0,
  hostsSelected: [],
  showRightFilters: false,
  selectAll: false
};

export default initialState;
