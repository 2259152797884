import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  padding-left: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Input = styled.input`
  width: 100%;
  height: 34px;
  border: 1px solid transparent;
  border-bottom: none;
  background-color: #edf2f7;
  padding: 0 10px;
  font-size: 13.5px;
  line-height: 2.48;
  box-shadow: inset 0 -1px 0 0 ${(props) => (props.required ? `${colors.redPink}` : '#cfd1de')};
  color: #090909;
  margin-bottom: 22px;
  &::placeholder {
    color: #bec8d2;
    font-size: 12.5px;
  }
`;
Input.displayName = 'Input';

export const InputTitle = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 2.08;
  margin-bottom: 1px;
  color: ${(props) => (props.required ? `${colors.redPink}` : '#8f94b2')};
`;
InputTitle.displayName = 'InputTitle';
