import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 12.5px;
  letter-spacing: 0.09px;
  color: #1d1d1d;
  padding-bottom: 8px;
`;
Label.displayName = 'Label';
