import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper, TrashIcon, CopyIcon, EditIcon
} from './styled';

const ActionBar = ({ handleCopyJob, handleDeleteJob, handleJobSelected }) => (
  <Wrapper>
    <EditIcon onClick={ handleJobSelected } />
    <CopyIcon onClick={ handleCopyJob } />
    <TrashIcon onClick={ handleDeleteJob } />
  </Wrapper>
);

ActionBar.propTypes = {
  handleCopyJob: PropTypes.func.isRequired,
  handleDeleteJob: PropTypes.func.isRequired,
  handleJobSelected: PropTypes.func.isRequired
};

export default ActionBar;
