import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';
import { bpd } from 'utils/breakpoints';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 500;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: 100%;
  right: 0;
  background: ${colors.paleGrey};
  box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
  ${bpd('xsm')`
    max-width: 75%;
  `}
  ${bpd('sm')`
    max-width: 50%;
  `}
  ${bpd('md')`
    max-width: 714px;
  `}
  ${fadeIn}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
