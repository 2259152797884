import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobName } from 'store/Jobs/selectors';
import { setName } from 'store/Jobs/actions';
import { Wrapper, Label, Input } from './styled';

const Name = () => {
  const dispatch = useDispatch();
  const name = useSelector(selectJobName);

  const onChangeDebounce = debounce((value) => {
    dispatch(setName(value));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useCallback((value) => onChangeDebounce(value), []);

  const onChange = (e) => onChangeCallBack(e.target.value);

  return (
    <Wrapper>
      <Label>Name *</Label>
      <Input defaultValue={ name } onChange={ onChange } />
    </Wrapper>
  );
};

export default Name;
