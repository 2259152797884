import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37px;
  min-height: 100%;
  > :nth-child(1){
    flex-grow: ${(props) => (props.select ? 1 : 0)};
  }
`;
Wrapper.displayName = 'Wrapper';

export const Line = styled.div`
  width: 1px;
  border: ${(props) => (props.last ? 'none' : 'solid 1px #979797')};
  content: ' ';
  flex-grow: 1;

`;
Line.displayName = 'Line';
