import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 37px;
  width: 100%;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  & > *:first-child::after {
    width: 1px;
    content: ' ';
    background: transparent;
    border-right: 1px #B5BEC6;
    border-right-style: dashed;
    height: 13px;
    margin: 0 0 0 15px;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #1c2b43;
  user-select: none;
`;
Title.displayName = 'Title';
