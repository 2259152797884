import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
`;
Backdrop.displayName = 'PopupBackdrop';

export const DropdownContainer = styled.div`
    position: absolute;
    overflow: hidden;
    left: ${({ isFirst, offset }) => (isFirst ? '0' : `calc(100% + ${offset}px)`)};
    top: calc(100% + 1px);
    background-color: ${colors.white};
    z-index: 2;
    max-height: 188px;
    width: ${({ isFirst, isCustomField }) => (isFirst || isCustomField) ? '320px' : '180px'};
    border-radius: 3px;
    box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
    ${fadeIn(0, 1)}
`;

DropdownContainer.displayName = 'DropdownContainer';

export const OptionsContainer = styled.div`
    overflow-y: auto;
    margin: 9px 0px 9px 0px;
    max-height: 170px;
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    padding-right: 13px;
    padding-left: 21px;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1c2243;
    height: 34px;
    text-align: left;
    &:hover {
        background-color: ${colors.iceBlue};
    }
    ${({ isOneOfOp }) => isOneOfOp && `
        font-size: 11px;
        height: 40px;
    `}
`;

export const OptionLabel = styled.div`
    width: 95%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${({ isOneOfOp }) => isOneOfOp && `
        text-align: center;
        white-space: normal;
    `}
`;
