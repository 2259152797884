import React from 'react';
import { useDispatch } from 'react-redux';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { deleteServicesSelected } from 'store/Services/actions';
import { deleteServicesGeneralSelected } from 'store/ServicesGeneral/actions';
import { deleteServicesSelectedFromAsset } from 'store/Contextualization/AssetDetail/actions';

const DeleteConfirmationModal = ({
  showDeleteConfirmation, entity, isFetching, servicesSelected, hideServiceModalDelete,
  selectAll, servicesCount
}) => {
  const dispatch = useDispatch();
  const count = selectAll ? servicesCount : servicesSelected.length;

  const deleteServices = {
    services: () => dispatch(deleteServicesSelected()),
    servicesGeneral: () => dispatch(deleteServicesGeneralSelected()),
    servicesAsset: () => dispatch(deleteServicesSelectedFromAsset())
  };

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ deleteServices[entity] }
      handleClose={ hideServiceModalDelete }
      entity="service"
      count={ count }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
