import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  `;
Wrapper.displayName = 'Wrapper';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 27px 33px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background-color: #fafbfc;
`;
ModalContent.displayName = 'ModalContent';

export const EditorWrapper = styled.div`
  flex: 1 1 0px;
  margin-top: ${({ isBulk }) => isBulk ? '14px' : '0'};
`;
EditorWrapper.displayName = 'EditorWrapper';

export const BulkDescription = styled.p`
  font-size: 12.5px;
  color: #63758d;
`;
BulkDescription.displayName = 'BulkDescription';

export const Title = styled.h5`
  color: ${colors.darkBlueGrey};
  font-weight: 600;
  font-size: 17.5px;
  margin-bottom: 13px;
`;
Title.displayName = 'Title';

export const ChoiceWrapper = styled.div`
  height: 130px;
  background-color: ${colors.white};
  padding: 13px 0 9px 20px;
`;
ChoiceWrapper.displayName = 'ChoiceWrapper';

export const DateWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  height: 130px;
`;
DateWrapper.displayName = 'DateWrapper';

export const DateBox = styled.div`
  width: 100%;
  height: 49px;
  margin: 7px 0 11px;
  padding: 16px 8px 5px 20px;
  background-color: #f4f7fa;
  font-size: 12.5px;
  color: ${({ isEmpty }) => isEmpty ? colors.blueGrey : colors.darkBlueGrey};
  cursor: pointer;
`;
DateBox.displayName = 'DateBox';

export const InputWrapper = styled.div`
  position: absolute;
  top: 47px;
  left: 20px;
`;
InputWrapper.displayName = 'InputWrapper';

export const DateFieldName = styled(Title)`
  padding: 9px 20px 0 20px;
  margin: 0;
`;
DateFieldName.displayName = 'DateFieldName';
