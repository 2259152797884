import React from 'react';
import {
  Wrapper, Title, Description, EmptyImage
} from './styled';

const NoSelection = () => (
  <Wrapper>
    <EmptyImage />
    <Title>No Job Selected</Title>
    <Description>Click a Job from the list to edit it’s contents</Description>
  </Wrapper>
);
export default NoSelection;
