import React from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';
import { HomeIcon, IconButton } from './styled';

const HomeButton = () => {
  const dispatch = useDispatch();
  return (
    <IconButton onClick={ () => dispatch(redirect('/workspaces')) }>
      <HomeIcon />
    </IconButton>
  );
};

export default HomeButton;
