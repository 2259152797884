import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobs, viewJob } from 'store/Jobs/actions';
import { Wrapper } from './styled';
import Header from './components/Header';
import Search from './components/Search';
import Items from './components/Items';

const List = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobs());
    return () => dispatch(viewJob(null));
  }, [dispatch]);

  return (
    <Wrapper>
      <Header />
      <Search />
      <Items />
    </Wrapper>
  );
};
export default List;
