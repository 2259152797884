import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allServicesAreSelected, selectSelectAllServices, selectServices, selectServicesSelected, someServiceIsSelected } from 'store/ServicesGeneral/selectors';
import { selectAllService, unSelectAllServices, unselectedRowFromSelectAll, unselectTotalServices } from 'store/ServicesGeneral/actions';
import Checkbox from 'Common/Components/Checkbox';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allServiceSelected = useSelector(allServicesAreSelected);
  const someServiceSelected = useSelector(someServiceIsSelected);
  const selectAll = useSelector(selectSelectAllServices);
  const services = useSelector(selectServices);
  const count = services.length;
  const servicesSelected = useSelector(selectServicesSelected);
  const dispatch = useDispatch();
  const allServicesSelected = someServiceSelected && (count === servicesSelected.length);
  const handleClick = () => {
    if (allServicesSelected || selectAll) {
      dispatch(unSelectAllServices());
      dispatch(unselectedRowFromSelectAll(true));
      dispatch(unselectTotalServices());
    } else {
      dispatch(selectAllService());
    }
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allServiceSelected } partialChecked={ someServiceSelected && !allServiceSelected } />
  );
};
