import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteAssetsSelected, hideHostModalDelete
} from 'store/Host/actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { deleteAssetsGeneral } from 'store/HostsGeneral/actions';

const DeleteConfirmationModal = ({ hostsSelected, showDeleteConfirmation, isFetching, entity, selectAll, hostsCount }) => {
  const dispatch = useDispatch();
  const count = selectAll ? hostsCount : hostsSelected.length;
  const deleteHosts = () => entity === 'assets' ? dispatch(deleteAssetsSelected()) : dispatch(deleteAssetsGeneral());

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ deleteHosts }
      handleClose={ () => dispatch(hideHostModalDelete()) }
      entity="asset"
      count={ count }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
