import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashSVG } from 'Images/icon-action-bar-trash.svg';
import { smallDot } from 'Styles/effects/dots';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 33px 28px;
  border-radius: 4px;
  background-color: ${colors.iceBlue};
  margin-bottom: 15px;
  ${smallDot(colors.purple5)}
`;
Wrapper.displayName = 'Wrapper';

export const TrashIcon = styled(TrashSVG)`
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
`;

TrashIcon.displayName = 'TrashIcon';
