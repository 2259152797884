import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { setBulkUpdateValue } from 'store/ManageGeneral/actions';
import { selectBulkUpdateCustomAttribute, selectModalBulkUpdateValue } from 'store/ManageGeneral/selectors';
import get from 'lodash/get';

const Input = () => {
  const dispatch = useDispatch();
  const customAttribute = useSelector(selectBulkUpdateCustomAttribute);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const onChange = (value) => dispatch(setBulkUpdateValue(value));
  const fieldName = get(customAttribute, 'field_display_name', '');
  const type = get(customAttribute, 'field_type', '');

  return (
    <DebouncedInput
      value={ bulkUpdateValue }
      onChange={ onChange }
      name={ fieldName }
      title={ fieldName }
      type={ type === 'str' ? 'text' : 'number' }
      focusOnMount
      hideArrows={ type === 'int' }
    />
  );
};

export default Input;
