import {
  GROUP_BY
} from './constants';

const initialState = {
  selectedEntity: '',
  saveEnabled: false,
  isAdvancedMode: false,
  vulns: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [
      {
        field: 'confirmed',
        direction: 'desc'
      },
      {
        field: 'severity',
        direction: 'desc'
      }
    ],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    expandedFilter: [],
    advancedFilter: '',
    filterError: ''
  },
  assets: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    expandedFilter: [],
    advancedFilter: '',
    filterError: ''
  },
  knowledgeBase: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [
      {
        field: 'severity',
        direction: 'desc'
      },
      {
        field: 'name',
        direction: 'asc'
      }
    ],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: []
  },
  vulnsAssets: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [
      {
        field: 'confirmed',
        direction: 'desc'
      },
      {
        field: 'severity',
        direction: 'desc'
      }
    ],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    advancedFilter: '',
    filterError: ''
  },
  workspaces: {
    page: 1,
    rowsPerPage: 10,
    customFilters: [],
    order_by: [],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    expandedFilter: [],
    advancedFilter: '',
    filterError: ''
  },
  dashboard: {
    confirmed: false,
    closed: false
  },
  services: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [
      {
        field: 'name',
        direction: 'asc'
      }
    ],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    advancedFilter: '',
    filterError: ''
  },
  assetsGeneral: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    expandedFilter: [],
    advancedFilter: '',
    filterError: ''
  },
  servicesGeneral: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    expandedFilter: [],
    advancedFilter: '',
    filterError: ''
  },
  vulnsGeneral: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [
      {
        field: 'confirmed',
        direction: 'desc'
      },
      {
        field: 'severity',
        direction: 'desc'
      }
    ],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    expandedFilter: [],
    advancedFilter: '',
    filterError: ''
  },
  servicesAssets: {
    page: 1,
    rowsPerPage: 50,
    customFilters: [],
    order_by: [
      {
        field: 'name',
        direction: 'asc'
      }
    ],
    group_by: GROUP_BY.none.key.value,
    filters: [],
    filterHistory: [],
    advancedFilter: '',
    filterError: ''
  }
};

export default initialState;
