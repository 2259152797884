import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';
import { selectLocation } from 'store/Router/selectors';
import { Wrapper, Item } from './styled';

const Tab = ({ name, url }) => {
  const location = useSelector(selectLocation);
  const isSelected = location.pathname.includes(url);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(redirect(url));
  };

  return (
    <Item onClick={ handleClick } isActive={ isSelected } data-tour={ name }>
      { name }
    </Item>
  );
};

const TabsList = ({ tabsList }) => (
  <Wrapper>
    { tabsList.map(({ name, url }) => <Tab key={ name } name={ name } url={ url } />) }
  </Wrapper>
);

export default TabsList;
