import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseError } from 'Images/icon_close_error.svg';

export const FilterErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  top: 48px;
  height: 36px;
  flex: 1 1 0;
  border-radius: 3px;
  background-color: rgba(234, 49, 88, 0.1);
  z-index: 2;
`;

export const FilterErrorLabel = styled.div`
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 21px;
  font-size: 12.5px;
  height: 14px;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color:  ${colors.redPink};
`;

export const CloseErrorIcon = styled(CloseError)`
    min-width: 37px;
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  `;
CloseErrorIcon.displayName = 'CloseErrorIcon';
