/* eslint-disable import/prefer-default-export */
import React from 'react';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import {
  setBulkUpdateField, setBulkUpdateValue, setShowContextMenu,
  updateVulnGeneral
} from 'store/ManageGeneral/actions';
import { useIntl } from 'react-intl';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { StatusIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';

const StatusItem = ({ type }) => {
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVuln = vulnsSelected[0];
  const dispatch = useDispatch();
  const intl = useIntl();
  const oneVulnSelected = vulnsSelected.length === 1;
  const isBulk = vulnsSelected.length > 1;

  const applyStatus = (status) => {
    if (isBulk) {
      dispatch(setBulkUpdateField('status'));
      dispatch(setBulkUpdateValue(status));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVulnGeneral(selectedVuln, 'status', status));
    dispatch(setShowContextMenu(false));
  };

  return (
    <MenuItem
      isSelected={ oneVulnSelected && selectedVuln.status === type }
      title={ intl.formatMessage({ id: `manage.cm.status.${type}` }) }
      onClick={ () => applyStatus(type) }
    />
  );
};

export const Status = () => {
  const intl = useIntl();

  return (
    <ContextSubMenu
      title={ intl.formatMessage({ id: 'manage.cm.status' }) }
      icon={ <StatusIcon /> }
    >
      <StatusItem type="open" />
      <StatusItem type="closed" />
      <StatusItem type="re-opened" />
      <StatusItem type="risk-accepted" />
    </ContextSubMenu>
  );
};
