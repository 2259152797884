/* eslint-disable import/prefer-default-export */
import React from 'react';
import get from 'lodash/get';
import { getRequestUrl } from 'services/api/connector';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { setShowContextMenu } from 'store/ManageGeneral/actions';
import { useIntl } from 'react-intl';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import copyToClipboard from 'utils/clipboard';
import { CopyApiLinkIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';

export const CopyApiLink = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;
  const vulnId = get(vulnsSelected, '[0]._id', 0);

  const onClick = () => {
    const vulnPath = `vulns/${vulnId}`;
    const link = getRequestUrl(vulnPath);
    copyToClipboard(link);
    dispatch(setShowContextMenu(false));
  };

  if (isBulk) return null;
  return (
    <MenuItem
      title={ intl.formatMessage({ id: 'manage.cm.copyApiLink' }) }
      icon={ <CopyApiLinkIcon /> }
      onClick={ onClick }
    />
  );
};
