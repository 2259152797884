import styled from 'styled-components';
import colors from 'Styles/colors';
import { smallDot } from 'Styles/effects/dots';
import headShake from 'Styles/effects/headShake';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 120px;
  padding: 18px;
  background-color: ${colors.iceBlue};
  max-height: 500px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  ${smallDot(colors.purple5)}
  ${({ highlight }) => highlight && headShake}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
