import styled from 'styled-components';
import { ReactComponent as HomeIcon_ } from 'Images/home.svg';

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 9px;
  height: 15px;
  display: flex;
`;
IconButton.displayName = 'IconButton';

export const HomeIcon = styled(HomeIcon_)`
`;
HomeIcon.displayName = 'HomeIcon';
