import colors from 'Styles/colors';
import styled from 'styled-components';
import { bigDot, smallDot, verticalLine } from 'Styles/effects/dots';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-left: 52px;
  ${({ showLine }) => showLine && verticalLine}
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  padding-bottom: 13px;
  ${bigDot('#8cc4fd')}
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 13.5px;
  color: ${colors.grey19};
  padding-bottom: 13px;
`;
Description.displayName = 'Description';

export const Buttons = styled.div`
  display: flex;
  position: relative;
  ${smallDot(colors.purple5)}
`;
Buttons.displayName = 'Buttons';
