/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './App.scss';
import './Styles/bootstrap.min.css';
import './Styles/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'Screens/Error';
import { validateSession } from 'Screens/Login/actions/Actions';
import { selectLocation } from 'store/Router/selectors';
import { selectIsLoggedIn, selectIsValidatingSession } from 'store/Sesion/selectors';
import { setUrlReturn } from 'Screens/Faraday/actions/Actions';
// import NavMenu from 'Screens/NavMenu';
// import SideBar from 'Screens/Sidebar';

import errorHandler from './Common/Handlers/ErrorHandler';
import FileUploadContext from './Common/Components/FileUploadContext';
// import CommandValidator from './Common/Components/CommandValidator';
import RedirectModal from './Common/Components/RedirectModal';
import { Content, Wrapper } from './styled';
import { selectShowProcessingQueue } from 'store/FileUploaderContext/selector';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

// import { selectShowLegacyUI } from 'store/Preferences/selectors';
import NavMenuContext from 'Screens/Contextualization/NavMenu';
import SideBarContext from 'Screens/Contextualization/Sidebar';
import TabsContext from 'Screens/Contextualization/Tabs';
import CommandValidatorContext from 'Common/Components/Contextualization/CommandValidatorContext';

const App = ({ logOut, children }) => {
  const location = useSelector(selectLocation);
  const isValidatingSession = useSelector(selectIsValidatingSession);
  const showImportModal = useSelector(selectShowProcessingQueue);
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));
  // const showLegacyUI = useSelector(selectShowLegacyUI);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    dispatch(setUrlReturn(location.pathname));
    dispatch(validateSession());
    errorHandler(logOut);
    setMounted(true);
  }, [dispatch]);

  if ((!mounted || isValidatingSession) && location.pathname !== '/') return null;
  if (isValidatingSession) return <SpinnerComponent height="100%" />;
  return (
    <>
      {/* { showLegacyUI
        ? <Wrapper legacyUI={ showLegacyUI }>
          <NavMenu />
          <SideBar />
          <Content legacyUI={ showLegacyUI }>
            { children }
          </Content>
          <RedirectModal />
          <Error />
          { (showImportModal && loggedIn) && <FileUploadContext /> }
          <CommandValidator />
          </Wrapper>
        :  */}
        <Wrapper>
          <NavMenuContext />
          <SideBarContext />
          <TabsContext />
          <Content>
            { children }
          </Content>
          <RedirectModal />
          <Error />
          { (showImportModal && loggedIn) && <FileUploadContext /> }
          <CommandValidatorContext />
        </Wrapper>
    </>

  );
};

export default App;
