/* eslint-disable no-nested-ternary */
import React, {
  useState, memo, useCallback
} from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectWorkspaces } from 'store/Workspace/selectors';
import Checkbox from 'Common/Components/Checkbox/styled';
import {
  selectFilteredPipelines, selectPipelineSelected
} from 'store/Pipelines/selectors';
import {
  viewDetail, enableDisablePipeline, deletePipeline, copyPipeline
} from 'store/Pipelines/actions';
import Modal from 'Common/Components/Modal';
import useModal from 'Hooks/useModal';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import ReduxModal from 'Common/Components/ReduxModal';
import { openModal } from 'store/modals/actions';
import { MODAL_RUN_PIPELINE } from 'store/modals/modals';
import { redirect } from 'store/Router/actions';
import Run from '../../../Run';
import ActionBar from '../ActionBar';

import {
  Wrapper, Box, LeftIcons, RunIcon, PipelineData, Name, Description, Workspace,
  TopData, DisabledRunIcon
} from './styled';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const Pipeline = memo(({
  pipeline, handleClick, selected, handleDeletePipeline, handleCopy, handleRunPipeline
}) => {
  const {
    name, description, workspace_id, enabled, id, jobs, running
  } = pipeline;
  const dispatch = useDispatch();
  const [showActionBar, setShowActionBar] = useState(false);
  const handleOnMouseEnter = () => setShowActionBar(true);
  const handleOnMouseLeave = () => setShowActionBar(false);
  const workspaceList = useSelector((state) => selectWorkspaces(state));
  const workspace = workspaceList && workspaceList.filter((ws) => ws._id === workspace_id);
  const workspaceName = workspace.length ? workspace[0].name : '';

  const onChangeStatus = () => {
    dispatch(enableDisablePipeline(id, enabled));
  };

  const showModal = () => {
    if (jobs.length !== 0 && enabled) {
      handleRunPipeline(id, workspaceName);
      dispatch(openModal(MODAL_RUN_PIPELINE));
    }
  };

  return (
    <Box onMouseEnter={ handleOnMouseEnter } onMouseLeave={ handleOnMouseLeave } selected={ selected }>
      <LeftIcons>
        <Checkbox disabled={ isEmpty(workspaceName) } checked={ enabled } onClick={ onChangeStatus } title={ isEmpty(workspaceName) ? 'Pipeline without workspace cannot be enabled' : enabled ? 'Enabled' : 'Disabled' } width="30px" height="9px" diameterCircle="16px" checkedPosition="13px" />
        {running
          ? <SpinnerComponent size="16px" containerHeight="30px" />
          : enabled ? <RunIcon onClick={ showModal } title="Run Pipeline Now" /> : <DisabledRunIcon title="Disabled pipelines cannot be triggered" />}
      </LeftIcons>
      <PipelineData>
        <TopData>
          <Name onClick={ () => handleClick(pipeline) }>{ name }</Name>
          <Description>{ description }</Description>
        </TopData>
        <Workspace>{ workspaceName }</Workspace>
      </PipelineData>
      <ActionBar showActionBar={ showActionBar } pipeline={ pipeline } handleClick={ handleClick } id={ id } handleDeletePipeline={ handleDeletePipeline } handleCopy={ handleCopy } />
    </Box>
  );
});

Pipeline.displayName = 'Pipeline';

const Items = () => {
  const dispatch = useDispatch();
  const pipelines = useSelector((state) => selectFilteredPipelines(state));
  const pipelineSelected = useSelector((state) => selectPipelineSelected(state));
  const [pipelineToDelete, setPipelineToDelete] = useState();
  const [ModalWarning, showModalWarning, toggleModalWarning] = useModal(Modal);
  const [pipelineToRun, setPipelineToRun] = useState();

  const handleDeletePipeline = useCallback((e, pipeline) => {
    e.stopPropagation();
    setPipelineToDelete(pipeline);
    toggleModalWarning(true);
  }, [toggleModalWarning]);

  const handleSubmitDelete = () => {
    setPipelineToDelete(null);
    toggleModalWarning(false);
    dispatch(deletePipeline(pipelineToDelete));
  };

  const handleCloseDelete = () => {
    setPipelineToDelete(null);
    toggleModalWarning(false);
  };

  const handleCopy = useCallback((e, pipeline) => {
    e.stopPropagation();
    dispatch(copyPipeline(pipeline));
  }, [dispatch]);

  const handleClick = (pipeline) => {
    dispatch(viewDetail(pipeline, true));
    dispatch(redirect(`/automation/pipelines/${pipeline.id}`));
  };

  const handleRunPipeline = (id, workspace) => setPipelineToRun({ id, workspace });

  return (
    pipelines.length > 0 &&
    (
    <Wrapper>
      { pipelines.map((pipeline) => (
        <Pipeline
          key={ pipeline.id }
          pipeline={ pipeline }
          handleRunPipeline={ handleRunPipeline }
          handleClick={ () => handleClick(pipeline) }
          selected={ isEqual((pipelineSelected && pipelineSelected.id), pipeline.id) }
          handleDeletePipeline={ (e) => handleDeletePipeline(e, pipeline) }
          handleCopy={ (e) => { handleCopy(e, pipeline); } }
        />
      ))}
      { !isEmpty(pipelineToRun) && (
        <ReduxModal id={ MODAL_RUN_PIPELINE }>
          <Run id={ pipelineToRun.id } workspace={ pipelineToRun.workspace } />
        </ReduxModal>
      )}

      { showModalWarning && (
        <ModalWarning
          onAccept={ handleSubmitDelete }
          onClose={ handleCloseDelete }
          title="Warning"
        >
          You´re about to delete a pipeline, proceed?
        </ModalWarning>
      )}
    </Wrapper>
    )
  );
};

Pipeline.propTypes = {
  pipeline: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDeletePipeline: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired,
  handleRunPipeline: PropTypes.func.isRequired
};

export default Items;
