import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPipelines, getJobs } from 'store/Pipelines/actions';
import { selectPipelines } from 'store/Pipelines/selectors';
import Header from './components/Header';
import Search from './components/Search';
import Items from './components/Items';
import { Wrapper } from './styled';

const List = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPipelines());
    dispatch(getJobs());
  }, [dispatch]);

  const pipelines = useSelector((state) => selectPipelines(state));

  return (
    <Wrapper>
      <Header />
      <Search />
      { pipelines.length > 0 && <Items pipelines={ pipelines } /> }
    </Wrapper>
  );
};

export default List;
