import styled from 'styled-components';
import colors from 'Styles/colors';
import { customFieldContent } from 'Styles/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 34px;
  > :nth-child(1){
    margin-right: 8px;
  }
`;

Buttons.displayName = 'Buttons';

export const Content = styled.span`
  ${customFieldContent}
`;

Content.displayName = 'Content';

export const Label = styled.div`
  background-color: ${({ isFocused }) => isFocused ? colors.iceBlue : 'white'};
  font-size: 14px;
  text-align: left;
  color: #1c2243;
  cursor: ${({ isSelected }) => isSelected ? 'default' : 'pointer'};
  padding: 7px 5px 7px 22px;
  height: 36px;
  text-transform: none;
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${({ isSelected }) => isSelected ? 600 : 300};
`;
Label.displayName = 'Label';
