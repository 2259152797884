import React from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, Description, EmptyImage
} from './styled';

const NoSelection = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <EmptyImage />
      <Title>{ intl.formatMessage({ id: 'pipelines.noSelection.title' }) }</Title>
      <Description>{ intl.formatMessage({ id: 'pipelines.noSelection.description' }) }</Description>
    </Wrapper>
  );
};
export default NoSelection;
