import React from 'react';
import PropTypes from 'prop-types';
import { StyledCircle, Label } from 'Common/Components/Circle/styled';

const Circle = ({ label, labelColor, color }) => (
  <StyledCircle color={ color }>
    <Label color={ labelColor }>{ label }</Label>
  </StyledCircle>
);

Circle.defaultProps = {
  label: '',
  labelColor: ''
};

Circle.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelColor: PropTypes.string
};

export default Circle;
