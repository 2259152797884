import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFiltering, selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import {
  allServicesAreSelected, selectSelectAllServices, selectServicesCount,
  selectServicesSelected
} from 'store/Services/selectors';
import { selectTotalServices, unSelectAll, unselectTotalServices, unselectedRowFromSelectAll } from 'store/Services/actions';
import { Wrapper, Text, Blue, Bold } from 'Screens/Contextualization/Manage/components/ManageTable/components/SelecAll/styled';

const SelectAll = () => {
  const dispatch = useDispatch();
  const allVisualServicesSelected = useSelector(allServicesAreSelected);
  const pageLimit = useSelector((state) => selectRowsPerPage('services', state));
  const page = useSelector((state) => selectPage('services', state));
  const selectAll = useSelector(selectSelectAllServices);
  const servicesSelected = useSelector(selectServicesSelected);
  const serviceCount = useSelector(selectServicesCount);
  const isFiltering = useSelector(state => selectIsFiltering('services', state));

  const handleSelectAll = () => {
    dispatch(selectTotalServices());
  };
  const handleClear = () => {
    dispatch(unselectTotalServices());
    dispatch(unSelectAll());
    dispatch(unselectedRowFromSelectAll(false));
  };

  const content = () => {
    if (selectAll) {
      return (
            <Text>
        All <Bold>{serviceCount}</Bold> Services on this {isFiltering ? 'search' : 'Workspace'} are selected. <Blue onClick={ handleClear }>Clear Selection</Blue>
            </Text>
      );
    } else if (isFiltering) {
      return (
<Text>
        All <Bold>{servicesSelected.length}</Bold> Services on this page are selected. <Blue onClick={ handleSelectAll }>Select all Services that match this search.</Blue>
</Text>
      );
    } else {
      return (
<Text>
        All <Bold>{servicesSelected.length}</Bold> Services on this page are selected. <Blue onClick={ handleSelectAll }>Select all {serviceCount} Services in Workspace.</Blue>
</Text>
      );
    }
  };
  if (!allVisualServicesSelected) return null;
  if ((servicesSelected.length < pageLimit && page === 1 && !allVisualServicesSelected) || serviceCount <= pageLimit) return null;
  return <Wrapper> {content()} </Wrapper>;
};

export default SelectAll;
