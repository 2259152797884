import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHostsSelected } from 'store/HostsGeneral/selectors';
import { selectHostRow } from 'store/HostsGeneral/actions';
import Checkbox from 'Common/Components/Checkbox';

export const CheckColum = ({ host }) => { // eslint-disable-line import/prefer-default-export
  const hostsSelected = useSelector((state) => selectHostsSelected(state));
  const dispatch = useDispatch();
  const isSelected = hostsSelected.find((hostSelected) => hostSelected._id === host._id);
  const handleClick = (e) => dispatch(selectHostRow(e, host));
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
