import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { setInputField } from 'store/Pipelines/actions';
import { selectInputField } from 'store/Pipelines/selectors';
import {
  Input, InputTitle, Wrapper
} from './styled';

const TextInput = ({ inputType }) => {
  const dispatch = useDispatch();
  const inputValue = useSelector((state) => selectInputField(state, inputType));
  const [value, setValue] = useState(inputValue);
  const intl = useIntl();
  const title = intl.formatMessage({ id: `pipelines.detail.inputTitle.${inputType}` });
  const placeholder = intl.formatMessage({ id: `pipelines.detail.inputPlaceholder.${inputType}` });
  const required = inputType === 'name' && !inputValue;

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const onChangeDebounce = debounce((value) => {
    dispatch(setInputField(inputType, value));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useCallback((value) => onChangeDebounce(value), []);

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallBack(e.target.value);
  };

  return (
    <Wrapper>
      <InputTitle required={ required }>
        { title }
      </InputTitle>
      <Input
        placeholder={ placeholder }
        onChange={ onChange }
        value={ value }
        required={ required }
      />
    </Wrapper>
  );
};

TextInput.propTypes = {
  inputType: PropTypes.string.isRequired
};

export default TextInput;
