import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Circle from 'Common/Components/Circle';
import colors from 'Styles/colors';
import { useDispatch } from 'react-redux';
import { getPipelines } from 'store/Pipelines/actions';
import JobList from './JobList';
import Step from '../Step';
import NewJob from './NewJob';
import {
  Wrapper, Header, StepContainer,
  HeaderContainer, Title, Description
} from './styled';

const JobSequence = () => {
  const bigCircle = <Circle color={ colors.blueGrey } />;
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  return (
    <Wrapper>
      <Header>
        <StepContainer>
          <Step icon={ bigCircle } />
        </StepContainer>
        <HeaderContainer>
          <Title>{ intl.formatMessage({ id: 'pipelines.detail.jobSequence.title' }) }</Title>
          <Description>{ intl.formatMessage({ id: 'pipelines.detail.jobSequence.description' }) }</Description>
        </HeaderContainer>
      </Header>
      <JobList />
      <NewJob />
    </Wrapper>
  );
};

export default JobSequence;
