import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { getData } from 'store/Host/actions';
import { getHostsData } from 'store/HostsGeneral/actions';

const RefreshButton = ({ entity }) => {
  const dispatch = useDispatch();

  const handleClick = () => entity === 'assets' ? dispatch(getData()) : dispatch(getHostsData());

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ handleClick } />
  );
};

export default RefreshButton;
