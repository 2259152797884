import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #FFF;
  border: 1px solid #d9e4ef;
  border-radius: 4px;
  padding: 25px 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;
  overflow: auto;
  padding-right: 15px;
`;
Content.displayName = 'Content';
