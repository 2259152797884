// Colors

export default {
  black: '#000',
  white: '#fff',
  white1: '#fafbfc',
  dark1: '#273e55',
  dark2: '#1c2243',
  dark3: '#292929',
  dark4: '#426485',
  dark5: '#53686f',
  dark6: '#707070',
  purple1: '#9b72ea',
  purple2: '#a581eb',
  purple3: '#c846ce',
  purple5: '#b95bba',
  warmPurple: '#a4247a',
  light1: '#fafcfd',
  light2: '#ededed',
  light3: '#e9e9e9',
  light4: '#eff1f4',
  paleGrey: '#fafbfc',
  grey1: '#c2cbd5',
  grey2: '#82b8e5',
  grey3: '#aea3a3',
  grey4: '#989898',
  grey5: '#8da6be',
  grey6: '#7c97b2',
  grey7: '#a6bcd1',
  grey8: '#afb3b8',
  grey9: '#adb6c1',
  grey10: '#b7b7b7',
  grey11: '#7a7e8f',
  grey12: '#bec8d2',
  grey13: '#dcdcdc',
  grey14: '#ececec',
  grey15: '#bdbdbd',
  grey16: '#cfcfcf',
  grey17: '#afb1bc',
  grey18: '#c6c5c4',
  grey19: '#63758d',
  grey20: '#c5c5c5',
  grey21: '#dbe2e7',
  grey25: '#e3e7ec',
  grey26: '#d6d6d6',
  grey27: '#B0C8DF',
  grey30: '#afafaf',
  grey31: '#f7fbff',
  blue1: '#33495e',
  blue2: '#586f85',
  blue3: '#e7e8e9',
  blue4: '#ebeef2',
  blue5: '#8da6be',
  blue6: '#1c2243',
  blue7: '#e2f0fb',
  blue8: '#edf2f7',
  blue9: '#419bf9',
  blue10: '#7ebbfb',
  blue11: '#2996f0',
  blue12: '#ebeff3',
  darkBlueGrey: '#1c2243',
  blueCerulean: '#0082ed',
  softBlue: '#53a9ff',
  iceBlue: '#edf2f7',
  red1: '#dc4a4a',
  red2: '#e26464',
  red3: '#ea3158',
  redPink: '#ea3158',
  orange1: '#f1ae62',
  orange2: '#f59220',
  green1: '#88cc3f',
  green2: '#a1ce31',
  green3: '#74ab39',
  green4: '#d5f3b5',
  apple: '#88cc3f',
  blueGrey: '#90a9c0',
  tangerine: '#f59220',
  yellow1: '#fffced',
  veryLightPink: '#d6d6d6',
  greyBlue: '#63758d'
};
