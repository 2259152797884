import styled from 'styled-components';

import { ReactComponent as TrashSVG } from 'Images/icon-action-bar-trash.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 25px;
  margin-bottom: 16px;
`;

Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  height: 17px;
  margin: 5px auto 16px 0px;
  user-select: none;
  text-align: left;
  font-size: 14.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
`;

Title.displayName = 'Title';

export const TrashIcon = styled(TrashSVG)`
  width: 17px;
  height: 17px;
  margin: auto 0px auto 0px;
`;

TrashIcon.displayName = 'TrashIcon';
