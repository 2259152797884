import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { viewDetail, createPipeline } from 'store/Pipelines/actions';
import { selectInputField, selectJobsSelected } from 'store/Pipelines/selectors';
import { redirect } from 'store/Router/actions';
import BlueButton from 'Common/Components/BlueButton';
import {
  Wrapper, Title, ActionBar, CancelButton
} from './styled';

const Header = () => {
  const name = useSelector((state) => selectInputField(state, 'name'));
  const jobs = useSelector(selectJobsSelected);
  const intl = useIntl();
  const dispatch = useDispatch();

  const allowSavePipeline = () => !isEmpty(name) &&
  !isEmpty(jobs);

  const handleCancel = () => {
    dispatch(redirect('/automation/pipelines'));
    dispatch(viewDetail(null));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'pipelines.detail.header.title' }) }</Title>
      <ActionBar>
        <CancelButton onClick={ handleCancel }>{ intl.formatMessage({ id: 'pipelines.detail.header.cancelButton' }) }</CancelButton>
        <BlueButton onClick={ () => dispatch(createPipeline()) } label={ intl.formatMessage({ id: 'pipelines.detail.header.saveButton' }) } disabled={ !allowSavePipeline() } className="create-button" />
      </ActionBar>
    </Wrapper>
  );
};

export default Header;
