import styled from 'styled-components';
import { LinkAsButton } from 'Common/Components/LinkAsButton/styled';

export const MessageWrapper = styled.div`
  width: 450px;
  user-select: none;
`;

MessageWrapper.displayName = 'MessageWrapper';

export const StyledLink = styled(LinkAsButton)`
  padding: 0px;
`;

MessageWrapper.displayName = 'MessageWrapper';
