import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { redirect } from 'store/Router/actions';
import { newPipeline } from 'store/Pipelines/actions';
import Button from './styled';

const CreateButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(redirect('/automation/pipelines/new'));
    dispatch(newPipeline());
  };
  return (
    <Button title="Create" onClick={ handleClick }>
      <PlusIcon />
      {intl.formatMessage({ id: 'pipelines.list.header.createButton' }) }
    </Button>
  );
};

export default CreateButton;
