import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectCurrentHost } from 'store/Host/selectors';
import { selectedForWorking } from 'store/Contextualization/AssetDetail/selectors';
import { selectPathname } from 'store/Router/selectors';
import { selectWsDetailName } from 'store/Workspace/selectors';

export const selectTabsWithWorkspace = (state) => {
  const ws = selectCurrentWorkspace(state);

  return [
    {
      name: 'Dashboard',
      url: `/feed/${ws}`
    },
    {
      name: 'Assets',
      url: `/host/${ws}`
    },
    {
      name: 'Services',
      url: `/service/${ws}`
    },
    {
      name: 'Vulnerabilities',
      url: `/manage/${ws}`
    }
  ];
};

export const selectTabs = () => {
  return [
    {
      name: 'Workspaces',
      url: '/workspaces'
    },
    {
      name: 'Assets',
      url: '/hosts'
    },
    {
      name: 'Services',
      url: '/services'
    },
    {
      name: 'Vulnerabilities',
      url: '/vulnerabilities'
    }
  ];
};

export const selectAssetTabs = (state) => {
  const ws = selectCurrentWorkspace(state);
  const host = selectCurrentHost(state);
  const hostId = get(host, 'id', 0);
  return [
    {
      name: 'Services',
      url: `/host/${ws}/${hostId}/services`
    },
    {
      name: 'Vulnerabilities',
      url: `/host/${ws}/${hostId}/vulns`
    }
  ];
};

export const generalView = (state) => {
  const pathname = selectPathname(state);
  const workspaceDetailName = selectWsDetailName(state);
  const isInGeneralView = (pathname === '/workspaces') ||
    (pathname.startsWith('/workspaces')) ||
    (pathname === `/workspaces/${workspaceDetailName}`) ||
    (pathname.startsWith('/hosts')) ||
    (pathname.startsWith('/vulnerabilities')) ||
    (pathname.startsWith('/services'));

  return isInGeneralView;
};

export const showTabsWithWs = (state) => {
  const pathname = selectPathname(state);
  const currentWs = selectCurrentWorkspace(state);
  const host = selectCurrentHost(state);
  const isInsideWs = pathname.startsWith('/feed/') ||
  (pathname === `/host/${currentWs}/${host.id}`) ||
  (pathname === `/host/${currentWs}`) ||
  pathname.startsWith('/manage/') ||
  pathname.startsWith('/service/');

  return isInsideWs;
};

export const insideAsset = (state) => {
  const pathname = selectPathname(state);
  const currentWs = selectCurrentWorkspace(state);
  const host = selectCurrentHost(state);
  const assetService = selectedForWorking('services', state);
  const assetVuln = selectedForWorking('vulns', state);

  const isInAsset = host && ((pathname === `/host/${currentWs}/${host.id}/vulns`) ||
    (pathname === `/host/${currentWs}/${host.id}/services`) ||
    (pathname === `/host/${currentWs}/${host.id}/services/${assetService.id}`) ||
    (pathname === `/host/${currentWs}/${host.id}/vulns/${assetVuln._id}`));

  return isInAsset;
};
