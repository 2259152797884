/* eslint-disable no-negated-condition */
import React, { useState } from 'react';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import {
  Wrapper, StyledTitle, ValuesContainer, Value,
  DataContainer, GroupWrapper, Header, TrashButton,
  Trash, Arrow, ArrowDown, ArrowRight, GroupTitle,
  Item, Label, Text, Dash, ItemValues
} from './styled';
import { InputFilterGroupStyle } from 'Common/styles/style';

const formatOptionLabel = (opt) => {
  return (
    <Label>
      <Text title={ opt.value.parent_name }>{opt.value.parent_name}</Text>
      <Dash>-</Dash>
      <Text title={ opt.label }>{opt.label}</Text>
      <Dash>-</Dash>
      <Text title={ opt.value.protocol }>{opt.value.protocol}</Text>
    </Label>
  );
};

const Group = ({ itemData, removeGroup, removeValue, groupNameField, showDetails }) => {
  const [expanded, setExpandGroup] = useState(true);
  const isOdd = (n) => n % 2 !== 0;

  return (
    <GroupWrapper>
      <Header>
        <GroupTitle title={ itemData.label }>{ itemData.label }</GroupTitle>
        <Arrow onClick={ () => setExpandGroup(!expanded) }>
          { expanded ? <ArrowDown /> : <ArrowRight /> }
        </Arrow>
        <TrashButton name={ itemData.label } onClick={ (e) => removeGroup(e, groupNameField) }>
          <Trash />
        </TrashButton>
      </Header>
      { showDetails
        ? <ValuesContainer expanded={ expanded }>
          { itemData.items.map((element, i) =>
            <Item key={ element.id } isOdd={ isOdd(i) }>
              <ItemValues>
                <Text title={ element.parent_name }>{ element.parent_name }</Text>
                <Dash>-</Dash>
                <Text title={ element.name }>{ element.name }</Text>
                <Dash>-</Dash>
                <Text title={ element.protocol }>{ element.protocol }</Text>
              </ItemValues>
              <TrashButton name={ element.name } onClick={ (e) => removeValue(e) }>
                <Trash />
              </TrashButton>
            </Item>
          )}
          </ValuesContainer>
        : <ValuesContainer expanded={ expanded }>
          { itemData.items.map((element, i) =>
            <Item key={ element.id } isOdd={ isOdd(i) }>
              <Value title={ element.name }>{ element.name }</Value>
              <TrashButton name={ element.name } onClick={ (e) => removeValue(e) }>
                <Trash />
              </TrashButton>
            </Item>
          )}
          </ValuesContainer>
      }
    </GroupWrapper>
  );
};
const InputFilterGrouped = ({
  options, required, title, id, placeholder, disabled, groupNameField,
  addItems, addedItems, setItems, width, bgColor, menuHeight, setError,
  cantSelect, showDetails
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const parsedData = !isEmpty(options) && options.map((el) => {
    let newItems = [];
    el.items.forEach((item) => {
      if (isEmpty(addedItems)) {
        newItems = [...newItems, { value: item, label: item.name }];
      } else if (!addedItems.some(e => e.label === item.name)) {
        newItems = [...newItems, { value: item, label: item.name }];
      }
    });
    return { groupName: el.groupName, items: newItems };
  });

  const groupedOptions = !isEmpty(parsedData) ? parsedData.map((el) => { return { label: el.groupName, options: el.items }; }) : [];

  const getDataToRender = (groupNameField) => {
    let list = [];
    if (!isEmpty(addedItems) && !isEmpty(options)) {
      options.forEach((el) => {
        const isGroupSelected = addedItems.some(sel => sel.value[groupNameField] === el.groupName);
        if (isGroupSelected) {
          list = [...list, { label: el.groupName, items: [] }];
          addedItems.forEach((item) => {
            if (item.value[groupNameField] === el.groupName) {
              list.forEach((listItem, i) => {
                if (listItem.label === item.value[groupNameField]) list[i] = { ...listItem, items: [...listItem.items, item.value] };
              });
            }
          });
        }
      });
    }
    return list;
  };

  const dataToRender = getDataToRender(groupNameField);

  const removeValue = (e) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = addedItems.find((val) => val.value.name === buttonName);
    if (!removedValue) return;
    setItems(addedItems.filter((val) => val.value.name !== buttonName));
  };

  const removeGroup = (e, groupNameField) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = addedItems.find((val) => val.value[groupNameField] === buttonName);

    if (!removedValue) return;
    setItems(addedItems.filter((val) => val.value[groupNameField] !== buttonName));
  };

  const changeStyle = (newWidth, newBgColor = '#FFFFFF', menuHeight = '150px') => {
    const widthMenu = parseInt(newWidth.slice(0, newWidth.length - 2)) + 12;
    const newStyle = { ...InputFilterGroupStyle };
    const newControl = { ...newStyle.control(), width: newWidth };
    const newContainer = { ...newStyle.container(), backgroundColor: newBgColor };
    const newMenu = { ...newStyle.menu(), width: widthMenu };
    const newMenuList = { ...newStyle.menuList(), maxHeight: menuHeight };
    newStyle.control = () => newControl;
    newStyle.container = () => newContainer;
    newStyle.menu = () => newMenu;
    newStyle.menuList = () => newMenuList;
    return newStyle;
  };

  const style = (width || bgColor) ? changeStyle(width, bgColor, menuHeight) : InputFilterGroupStyle;

  return (
    <Wrapper isFocused={ isFocused }>
      <StyledTitle required={ required }>{ title }</StyledTitle>
      <Select
        onChange={ addItems }
        isMulti
        options={ groupedOptions }
        value={ addedItems }
        controlShouldRenderValue={ false }
        isClearable={ false }
        placeholder={ placeholder }
        isDisabled={ disabled }
        styles={ style }
        id={ id }
        onFocus={ () => { if (cantSelect) { setError(); } else { setIsFocused(!isFocused); } } }
        onBlur={ () => setIsFocused(!isFocused) }
        formatOptionLabel={ showDetails ? formatOptionLabel : null }
      />
      <DataContainer>
        { !isEmpty(dataToRender)
          ? dataToRender.map((val) => (
             <Group key={ val.label } itemData={ val } removeGroup={ removeGroup } removeValue={ removeValue } groupNameField={ groupNameField } showDetails={ showDetails } />
          ))
          : null}
      </DataContainer>
    </Wrapper>
  );
};

export default InputFilterGrouped;
