import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
Wrapper.displayName = 'Wrapper';

export const JobWrapper = styled.div`
  display: flex;
  width: 100%;
`;
JobWrapper.displayName = 'JobWrapper';

export const StepContainer = styled.div`
  margin-right: 25px;
`;
StepContainer.displayName = 'StepContainer';

export const SortableElement = styled.div`
  width: 100%;
`;
SortableElement.displayName = 'SortableElement';
