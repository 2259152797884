import get from 'lodash/get';
import { selectParsedCustomAttributes } from 'store/CustomAttributes/selectors';
import defaultFields from 'store/Manage/fields';

// eslint-disable-next-line import/prefer-default-export
export const selectManageFields = (state) => {
  let fields = get(state, 'preferences.manage_table', defaultFields);
  const excludedFields = ['description', 'params', 'pname', 'references', 'request', 'resolution', 'response', 'policyviolations', 'data', 'evidence', 'workspace_name'];

  fields = Object.keys(fields).reduce((filteredFields, field) => {
    if (!excludedFields.includes(field)) {
      filteredFields[field] = fields[field];
    }
    return filteredFields;
  }, {});

  const customFields = selectParsedCustomAttributes(state);

  return {
    ...customFields,
    ...fields
  };
};
