import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Impacts from 'Common/Components/Impacts';
import { selectModalBulkUpdateValue } from 'store/ManageGeneral/selectors';
import {
  addBulkUpdateValue,
  removeBulkUpdateValue
} from 'store/ManageGeneral/actions';

const Impact = () => {
  const dispatch = useDispatch();
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const isChecked = (impactType) => bulkUpdateValue.includes(impactType);
  const selectField = (field) => {
    if (isChecked(field)) dispatch(removeBulkUpdateValue(field));
    else dispatch(addBulkUpdateValue(field));
  };

  return (
    <Impacts
      hideTitle
      selectCheckbox={ selectField }
      integrity={ isChecked('integrity') }
      confidentiality={ isChecked('confidentiality') }
      availability={ isChecked('availability') }
      accountability={ isChecked('accountability') }
    />
  );
};

export default Impact;
