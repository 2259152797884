import colors from 'Styles/colors';
import styled from 'styled-components';

export const Workspace = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  color: ${colors.dark5};
`;
Workspace.displayName = 'Workspace';

export const Column = styled.div`
  font-size: 11.5px;
  font-weight: 400;
  color: ${colors.blueGrey};
`;
Column.displayName = 'Column';

export const CenteredNumericRow = styled(Column)`
  text-align: center;
`;
CenteredNumericRow.displayName = 'CenteredNumericRow';
