import React from 'react';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobModel, selectModels } from 'store/Jobs/selectors';
import { useIntl } from 'react-intl';
import { setModel } from 'store/Jobs/actions';
import { Wrapper } from './styled';

const ModelSelector = () => {
  const dispatch = useDispatch();
  const options = useSelector(selectModels);
  const value = useSelector(selectJobModel);
  const intl = useIntl();
  const getText = (id) => intl.formatMessage({ id });

  return (
    <Wrapper>
      <StandardDropdown
        width="200px"
        field="action"
        options={ options.map((id) => ({ name: getText(`jobs.models.${id}`), desc: getText(`jobs.models.${id}`), id })) }
        updateValue={ (f, item) => dispatch(setModel(item.id)) }
        defaultValue={ value }
        selectObject
      />
    </Wrapper>
  );
};

export default ModelSelector;
