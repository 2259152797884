import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const StepContainer = styled.div`
  margin-right: 25px;
`;
StepContainer.displayName = 'StepContainer';

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .create-button {
    width: 154px;
  }
`;
ActionsWrapper.displayName = 'ActionsWrapper';

export const SelectJob = styled.div`
  max-width: 367px;
`;
SelectJob.displayName = 'SelectJob';

export const Text = styled.span`
  color: ${colors.grey11};
  margin: 0 35px;
`;
Text.displayName = 'Text';

export const CreateButton = styled.div`
  width: 154px;
`;
CreateButton.displayName = 'CreateButton';

export const dropdownStyle = {
  control: () => ({
    height: '35px',
    width: '200px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '13px',
    color: '#1c2243',
    position: 'relative',
    fontWeight: 400
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    right: '4px',
    color: '#505965',
    width: '30px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    paddingRight: '22px',
    textTransform: 'capitalize',
    fontWeight: state.isSelected ? 600 : 300,
    textOverflow: 'unset',
    whiteSpace: 'unset',
    overflow: 'visible'
  }),
  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: ''
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};
