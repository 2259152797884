/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedEntity, selectCustomFieldsKey, selectFilterError, selectFilterHistory, selectIsFiltering, selectSearchFunction
} from 'store/Filters/selectors';
import { SEARCH_BY_ENTITIES, ENTITIES } from 'store/Filters/entitiesConstants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  addFilter, removeFilter, setSelectedEntity, clearFilterError, clearFilters, setSaveEnabled,
  addFilterHistory,
  rebuildFilters
} from 'store/Filters/actions';
import { createNameKey, CUSTOM_FIELDS_OPERATORS, SEARCH_BY_FILTER_KEYS } from 'store/Filters/constants';
import { selectLocation } from 'store/Router/selectors';
import DropdownOptions from './components/Dropdown';
import { FilterTags, DraftTag, Tag } from './components/FilterTags';
import {
  DropdownContainer, Input, SearchInput,
  TagsContainer, Backdrop, SearchIcon, ClearIcon, SearchButton
} from './styled';
import { filterByInput, useFocus } from './functions';
import DateInput from './components/DateInput';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectCurrentHost } from 'store/Host/selectors';
import { parseOptions } from 'Screens/Contextualization/Manage/components/CustomAttributesModal/functions';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';

const BasicFilter = () => {
  const [basicInputValue, setBasicInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [draftTag, setDraftTag] = useState({});
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedOp, setSelectedOp] = useState(null);
  const [selectedVal, setSelectedVal] = useState(null);
  const [inputRef, setInputFocus, setInputBlur] = useFocus();
  const tagsContainerRef = useRef(null);
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const currentAsset = useSelector(selectCurrentHost);
  const assetId = get(currentAsset, '_id', 0);
  const selectedEntity = useSelector(getSelectedEntity);
  const searchFunction = useSelector(selectSearchFunction);
  const isFiltering = useSelector((state) => selectIsFiltering(selectedEntity, state));
  const filterError = useSelector((state) => selectFilterError(selectedEntity, state));
  const filterHistory = useSelector((state) => selectFilterHistory(selectedEntity, state));
  const subKey = get(selectedKey, 'name.value', '');
  const isSubKey = subKey.includes('__') && (subKey !== 'host__os');
  const isCustomField = selectedKey && (selectedKey.name.value === 'custom_fields->');
  const customFields = useSelector(selectCustomAttributes);
  const customFieldsKeys = useSelector(selectCustomFieldsKey);
  const primaryKeyOperators = get(selectedKey, 'operators', []);
  const primaryKeyAutocomplete = get(selectedKey, 'autocomplete', []);
  const subKeyOperators = get(SEARCH_BY_FILTER_KEYS, `${subKey}.operators`, []);
  const subKeyAutocomplete = get(SEARCH_BY_FILTER_KEYS, `${subKey}.autocomplete`, []);

  const entityFilterKeys = get(ENTITIES, `${selectedEntity}.values`, []);
  const newEntityFilterKeys = selectedEntity.includes('vulns') ? entityFilterKeys.length > 0 ? [...entityFilterKeys, customFieldsKeys] : [] : entityFilterKeys;
  newEntityFilterKeys.sort((a, b) => a.name.label.localeCompare(b.name.label));

  const getChoiceAutocomplete = () => {
    const customField = customFields.find((cf) => cf.field_name === selectedKey.name.value);
    const options = parseOptions(customField.field_metadata);
    const parsedOptions = options.map((item) => createNameKey(item, item));
    return parsedOptions;
  };
  const filterKeyOperators = selectedKey?.type ? CUSTOM_FIELDS_OPERATORS[selectedKey.type] : isSubKey ? subKeyOperators : primaryKeyOperators;
  const filterKeyAutocomplete = selectedKey?.type === 'choice' ? getChoiceAutocomplete() : isSubKey ? subKeyAutocomplete : primaryKeyAutocomplete;

  const customFieldsAutocomplete = isCustomField && get(customFieldsKeys, 'autocomplete', []);

  const filteredEntityKeys = filterByInput(newEntityFilterKeys, basicInputValue);
  const filteredKeyOperators = filterByInput(filterKeyOperators, basicInputValue);

  const keysSupportsIsOneOf = ['cve_instances__name', 'target', 'ip'];
  const complexKeys = ['cve_instances__name', 'hostnames__name'];

  const supportsList = () => {
    const opSupportsList = draftTag?.op === 'is one of';
    return subKey && keysSupportsIsOneOf.includes(subKey) && opSupportsList;
  };

  const autocompleteInputSugestion = () => {
    const isDate = subKey.includes('date') || selectedKey?.type?.includes('date');
    if (isDate) return '';
    if (supportsList()) return ([createNameKey('Type one or more items, separated by commas', basicInputValue)]);
    return ([createNameKey(basicInputValue || 'Type your query', basicInputValue)]);
  };

  const filteredKeyAutocomplete = filterByInput(filterKeyAutocomplete, basicInputValue).length > 0 ? filterByInput(filterKeyAutocomplete, basicInputValue) : autocompleteInputSugestion();

  const currentLocationEntity = () => {
    if (location.pathname.includes(`host/${currentWorkspace}/${assetId}/vulns`)) return ENTITIES.vulnsAssets;
    else if (location.pathname.includes('/hosts')) return ENTITIES.assetsGeneral;
    else if (location.pathname.includes(`host/${currentWorkspace}/${assetId}/services`)) return ENTITIES.servicesAssets;
    else return SEARCH_BY_ENTITIES.find((e) => location.pathname.includes(e.pathname));
  };

  useEffect(() => {
    const entity = get(currentLocationEntity(), 'name.value', '');
    if (entity) dispatch(setSelectedEntity(entity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, assetId]);

  const onSearchGlassClick = () => {
    if (selectedEntity) {
      dispatch(searchFunction());
      dispatch(setSaveEnabled(true));
    }
  };

  const resetFilterIndexes = () => {
    setDraftTag({});
    setSelectedKey(null);
    setSelectedOp(null);
    setSelectedVal(null);
  };

  const onDropdownSearch = () => {
    if (searchFunction) dispatch(searchFunction());
    setShowDropdown(false);
    dispatch(setSaveEnabled(true));
    setInputBlur();
  };

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      setBasicInputValue('');
      setShowDropdown(false);
      setInputBlur();
      resetFilterIndexes();
    }
    if (e.key === 'Backspace') {
      const filterHistoryCount = filterHistory.length;

      if (!basicInputValue) {
        if (!isEmpty(draftTag)) resetFilterIndexes();
        else if (filterHistoryCount > 0) dispatch(removeFilter(selectedEntity, filterHistory[filterHistoryCount - 1]));
        else if (filterHistoryCount === 0) {
          dispatch(setSaveEnabled(false));
        }
      }
    }
  };

  const onSelect = (elementName, elementValue) => {
    let filterTag = {};
    if (elementName === 'key') {
      setSelectedKey(elementValue);
      filterTag = { name: elementValue.name.label };
    } else if (elementName === 'val') {
      onValueSelect(elementValue);
    } else {
      setSelectedOp(elementValue);
      filterTag = { ...draftTag, op: elementValue.name.label };
    }

    setBasicInputValue('');
    setInputFocus();
    setDraftTag(filterTag);
  };

  const onValueSelect = (selectedVal) => {
    const name = selectedKey.name.value;
    const op = selectedOp.name.value;
    const val = op === 'ilike' ? `%${selectedVal.name.value}%` : selectedVal.name.value;
    const type = get(selectedKey, 'type', '');

    let filter = {};

    if (selectedVal.name.value !== '') {
      setSelectedVal(selectedVal);

      const isComplexSearch = complexKeys.includes(name);
      const supportsList = (draftTag) && (draftTag.op === 'is one of');
      const stringWithCommas = val.includes(',');
      const parsedValues = stringWithCommas ? val.split(',').map((t) => t.trim()) : [];
      const multipleFilters = parsedValues?.length > 0;
      if (isComplexSearch && supportsList && stringWithCommas && multipleFilters) {
        const values = (name === 'cve_instances__name') ? parsedValues.map((v) => v.toUpperCase()) : parsedValues;
        const filters = [];
        values.forEach((v) => {
          const parsedFilter = {
            name: name.split('__')[0],
            op: primaryKeyOperators[0].name.value,
            val: {
              name: name.split('__')[1],
              op,
              val: v
            }
          };
          filters.push(parsedFilter);
          dispatch(addFilterHistory(selectedEntity, parsedFilter));
        });
        setBasicInputValue('');
        dispatch(rebuildFilters(selectedEntity, filters));
      } else if (supportsList && stringWithCommas && multipleFilters) {
        const filters = [];
        parsedValues.forEach((v) => {
          const parsedFilter = {
            name,
            op,
            val: v
          };
          filters.push(parsedFilter);
          dispatch(addFilterHistory(selectedEntity, parsedFilter));
        });
        setBasicInputValue('');
        dispatch(rebuildFilters(selectedEntity, filters));
      } else {
        if (isSubKey) {
          const value = ((name === 'cve_instances__name') || (name === 'cwe__name')) ? val.toUpperCase() : val;
          const complexKeys = ['cve_instances__name', 'hostnames__name', 'cwe__name'];
          const isComplexSearch = complexKeys.includes(name);
          const isNotOneOf = (draftTag) && (draftTag.op === '!=');
          filter = {
            name: name.split('__')[0],
            op: (isComplexSearch && isNotOneOf) ? primaryKeyOperators[1].name.value : primaryKeyOperators[0].name.value,
            val: {
              name: name.split('__')[1],
              op,
              val: value
            }
          };
        } else {
          filter = {
            name: type ? `custom_fields->${name}` : name,
            op,
            val
          };
        }

        setBasicInputValue('');

        dispatch(addFilter(selectedEntity, filter));
      }
      resetFilterIndexes();
      setShowDropdown(false);
      dispatch(setSaveEnabled(true));
    }
    setInputFocus();
  };

  const clearAllFilters = () => {
    if (selectedEntity) {
      if (filterError) dispatch(clearFilterError(selectedEntity));
      if (isFiltering) dispatch(clearFilters(selectedEntity));
      dispatch(searchFunction());
      dispatch(setSelectedEntity(''));
    }
    setBasicInputValue('');
    setInputBlur();
    resetFilterIndexes();
  };

  const onBackdropClickHandler = () => {
    setShowDropdown(false);
    resetFilterIndexes();
    setBasicInputValue('');
  };

  const tagsContainerWidth = tagsContainerRef.current ? tagsContainerRef.current.clientWidth : 44;
  const dropdownPosition = (tagsContainerWidth < 415) ? tagsContainerWidth : 415;

  const isVisible = {
    entity: showDropdown && !selectedEntity,
    key: showDropdown && !selectedKey,
    op: showDropdown && selectedKey && !selectedOp,
    val: showDropdown && selectedOp && !selectedVal,
    customFields: showDropdown && selectedKey && !selectedOp && selectedKey.name.value === 'custom_fields->'
  };

  const showDateInput = ((subKey.includes('date') || selectedKey?.type?.includes('date')) && isVisible.val);

  return (
    <>
      <SearchInput>
        <TagsContainer ref={ tagsContainerRef }>
          <FilterTags entity={ selectedEntity } onSearch={ () => dispatch(searchFunction()) } />
          <Tag type="val" value={ selectedKey?.type ? 'Custom Attribute' : '' } />
          <DraftTag draftTag={ draftTag } />
        </TagsContainer>
        { !showDateInput && (<Input
          ref={ inputRef }
          placeholder={ isFiltering ? '' : currentLocationEntity().placeholder }
          value={ basicInputValue }
          onChange={ (e) => setBasicInputValue(e.target.value) }
          onFocus={ () => setShowDropdown(true) }
          onKeyDown={ onKeyDown }
        />)}
        { showDateInput && <DateInput onValueSelect={ (val) => onSelect('val', createNameKey(val, val)) } />}
      </SearchInput>
      <DropdownContainer x={ dropdownPosition }>
        { isVisible.key && <DropdownOptions onSearch={ onDropdownSearch } options={ filteredEntityKeys } onSelect={ (val) => onSelect('key', val) } /> }
        { isVisible.customFields && <DropdownOptions onSearch={ onDropdownSearch } options={ customFieldsAutocomplete } onSelect={ (val) => onSelect('key', val) } /> }
        { isVisible.op && <DropdownOptions defaultSelect onSearch={ onDropdownSearch } options={ filteredKeyOperators } onSelect={ (val) => onSelect('op', val) } /> }
        { isVisible.val && <DropdownOptions isLastDropdown defaultSelect onSearch={ onDropdownSearch } options={ filteredKeyAutocomplete } onSelect={ onValueSelect } supportsList={ supportsList() } /> }
      </DropdownContainer>
      { selectedKey && <ClearIcon onClick={ clearAllFilters } /> }
      { showDropdown && <Backdrop onClick={ onBackdropClickHandler } /> }
      <SearchButton onClick={ onSearchGlassClick } label={ <SearchIcon /> } />
    </>
  );
};

export default BasicFilter;
