import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomFieldContainer from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomFieldContainer/index';
import CustomFieldContent from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomFieldContent/index';
import { CUSTOM_ATTRIBUTE_TYPES as types } from 'Screens/Manage/components/CustomAttributesModal/constants';
import toString from 'lodash/toString';
import isInteger from 'lodash/isInteger';
import trim from 'lodash/trim';

const CustomField = ({ field, initialValue, dataSelected, onSave, entity }) => {
  const [showContent, setShowContent] = useState(false);
  const onShowContent = () => setShowContent(true);
  const onHideContent = () => setShowContent(false);

  const onSaveCallback = () => {
    onShowContent(!!initialValue || isInteger(initialValue));
  };

  useEffect(() => {
    setShowContent(!!(trim(toString(initialValue))) || isInteger(initialValue));
  }, [field, initialValue]);

  return (
    <>
      <CustomFieldContainer key={ field.field_name } field={ field } onShow={ onShowContent } showContent={ showContent }>
        <CustomFieldContent vuln={ dataSelected } field={ field } onCancel={ onHideContent } initialValue={ initialValue } onSaveCallback={ onSaveCallback } onSaveCustomAttribute={ onSave } entity={ entity } />
      </CustomFieldContainer>
    </>
  );
};

CustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([types.STRING, types.INTEGER, types.LIST,
      types.MARKDOWN, types.CHOICE, types.DATE]).isRequired,
    field_metadata: PropTypes.string
  }).isRequired,
  initialValue: PropTypes.string,
  dataSelected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  onSave: PropTypes.func,
  entity: PropTypes.string
};

CustomField.defaultProps = {
  initialValue: '',
  dataSelected: [],
  onSave: null,
  entity: ''
};

export default CustomField;
