import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allHostsAreSelected, someHostIsSelected } from 'store/HostsGeneral/selectors';
import { selectAllHost } from 'store/HostsGeneral/actions';
import Checkbox from 'Common/Components/Checkbox';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allHostSelected = useSelector(allHostsAreSelected);
  const someHostSelected = useSelector(someHostIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(selectAllHost());
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allHostSelected } partialChecked={ someHostSelected && !allHostSelected } />
  );
};
