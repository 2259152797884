import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { setBulkUpdateValue } from 'store/ManageGeneral/actions';
import { selectModalBulkUpdateValue, selectErrorMessage } from 'store/ManageGeneral/selectors';

const NameInput = () => {
  const dispatch = useDispatch();
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const message = useSelector(selectErrorMessage);

  const onChange = (value) => dispatch(setBulkUpdateValue(value));

  return (
    <DebouncedInput
      value={ bulkUpdateValue }
      onChange={ onChange }
      name="name"
      title="vulnerability name"
      focusOnMount
      error={ !!message }
      mandatory={ !!message }
      errorMessage={ message }
    />
  );
};

export default NameInput;
